import { isAdmin, isSupportUser } from '../helpers/auth';
import { useRecoilValue } from 'recoil';
import { originalURL } from '../Atoms/auth';
import { logger } from '../helpers/logger';
import {
	authenticateWithMicrosoft,
	getCurrentOrganization,
	getCurrentUser,
	getUsersByOrg,
	login,
	loginWithTheCode,
	sendAuthCode,
} from '../api/api';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../helpers/notifications';
import { ensureActiveDirectoryCustomRolesExist } from '../api/sso';
import { useSearchParams } from 'react-router-dom';

export function useLoginLogic({
	setLoggingIn,
	setLoading,
	setAuthStatus,
	setAuthTokenExpirationTime,
	setCurrentUser,
	setOrganization,
	setOrganizationMembers,
	navigate,
}) {
	const originalUrl = useRecoilValue(originalURL);
	const [searchParams] = useSearchParams();

	const handleLoginLogic = async (skipLoginUser = false, redirectURL = '') => {
		try {
			setLoggingIn(true);
			// if (!skipLoginUser) await loginUser();
			const currentUserRequest = await handleGetCurrentUser();
			logger('info', 'Current User Request', currentUserRequest);
			const { id, email, firstName, lastName } = currentUserRequest;

			if (
				!currentUserRequest.roles.includes('support') &&
				!currentUserRequest.roles.includes('masteradmin')
			) {
				await handleGetCurrentOrganization(
					currentUserRequest.currentOrganizationID
				);
			}
			logger('info', 'Login was successful', currentUserRequest);
			const supportUser = isSupportUser(currentUserRequest.roles);

			let startingPath = supportUser ? '/admin/meetings' : '/meetings';
			if (redirectURL) {
				startingPath = redirectURL;
			} else if (
				typeof originalUrl === 'string' &&
				!['/login', '/signup', '', '/'].includes(originalUrl)
			) {
				// if role includes support, then make sure the originalUrl does not contain /admin. if it does, then redirect to /meetings.
				// if it does not, then redirect to the originalUrl.
				startingPath = originalUrl.length > 1 ? originalUrl : '/meetings';
			}

			navigate(startingPath);
		} catch (err) {
			logger('error', 'Error logging in', err);
		} finally {
			setLoggingIn(false);
		}
	};

	const handleSendAuthCode = async (email: string) => {
		setLoading(true);
		try {
			const response = await sendAuthCode(email);
			if (response?.success) {
				setLoading(false);
				showSuccessNotification({
					message:
						'Check your email for a one-time password to login to Reelay.',
				});
				logger('info', 'One time password sent was successful', response);
				return response;
			} else {
				logger('error', 'Error sending OTP', response);
				setLoading(false);
				showFailureNotification({
					title: 'Error',
					message:
						'Sorry, there was an error sending the one-time password. Please try again.',
				});
			}
		} catch (err) {
			logger('error', 'Error sending OTP', err);
			setLoading(false);
			showFailureNotification({
				title: 'Error',
				message:
					'Sorry, there was an error sending the one-time password. Please try again.',
			});
		}
	};

	const handleAuthCode = async (values: {
		authCode: string;
		email: string;
	}) => {
		try {
			setLoading(true);
			const res = await loginWithTheCode(values.authCode, values.email);

			if (res instanceof Error) {
				showFailureNotification({
					title: 'Verification code failed!',
					message: res.message,
				});
				return res;
			}
			setAuthStatus(true);
			setAuthTokenExpirationTime(res?.expiresAt);
			showSuccessNotification({
				message: 'You were logged in!',
			});
			await handleLoginLogic();
		} catch (err) {
			logger('error', 'Error logging in user', err);
			showFailureNotification({
				message: 'There was an error logging you in. Please try again.',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleGetCurrentUser = async (organizationID = '') => {
		try {
			const user = await getCurrentUser(organizationID);
			setCurrentUser(user);

			const { currentOrganizationID } = user;
			if (currentOrganizationID) {
				const usersResponse = await getUsersByOrg(currentOrganizationID);
				const users = usersResponse;
				setOrganizationMembers(users);
			}
			return user;
		} catch (err) {
			logger('error', 'Error getting current user', err);
		}
	};

	const handleGetCurrentOrganization = async (organizationID: string) => {
		try {
			const currentOrg = await getCurrentOrganization(organizationID);
			setOrganization(currentOrg.data.data);
			return currentOrg.data.data;
		} catch (err) {
			logger('error', 'Error getting current organization', err);
		}
	};

	const loginUser = async (magicToken: string) => {
		try {
			setLoading(true);
			const loginResponse = await login({ magicToken });
			setLoading(false);
			setAuthStatus(true);
			showSuccessNotification({
				message: 'You are logged in!',
			});

			return loginResponse.data.data;
		} catch (err) {
			logger('error', 'Error logging in user', err);
			showFailureNotification({
				message: 'There was an error logging you in. Please try again.',
			});
		} finally {
			setLoading(false);
		}
	};

	const loginWithMicrosoft = async () => {
		const redirectURL = localStorage.getItem(
			'microsoft_sso_setup_redirect_url'
		);
		try {
			if (searchParams.get('state')) {
				setLoading(true);
				const state = JSON.parse(searchParams.get('state') || '{}');
				if (state.type === 'microsoft_oauth') {
					const code = searchParams.get('code');
					if (code) {
						const res = await authenticateWithMicrosoft(code);
						if (redirectURL) {
							await ensureActiveDirectoryCustomRolesExist();
						}
						const authData = res?.data?.data;
						if (authData?.sessionToken) {
							setAuthStatus(true);
						} else {
							showFailureNotification({
								message:
									'Sorry, there was an error logging in with Microsoft. Please try again or another method.',
							});
							logger('error', 'Error logging in with Microsoft', res);
						}

						try {
							await handleLoginLogic(true, redirectURL);
							logger('info', 'Login was successful', res);
						} catch (err) {
							logger('error', 'Error logging in', err);
						} finally {
							setLoading(false);
						}
					}
				}
			}
		} catch (err) {
			navigate(redirectURL || '/');
			localStorage.removeItem('microsoft_sso_setup_redirect_url');
		} finally {
			setLoading(false);
		}
	};

	const checkAuthAndRedirect = (
		userAuthenticated,
		currentUser,
		originalURL
	) => {
		if (userAuthenticated) {
			let redirectPath = isSupportUser(currentUser?.roles)
				? '/admin/meetings'
				: '/meetings';

			if (
				typeof originalURL === 'string' &&
				!['/login', '/signup', '', '/'].includes(originalURL)
			) {
				redirectPath = originalURL.length > 1 ? originalURL : '/meetings';
			}
			navigate(redirectPath);
		}
	};

	return {
		handleLoginLogic,
		handleSendAuthCode,
		handleAuthCode,
		handleGetCurrentUser,
		handleGetCurrentOrganization,
		loginUser,
		loginWithMicrosoft,
		checkAuthAndRedirect,
	};
}
