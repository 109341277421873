import React from 'react';
import {
	Stack,
	Title,
	ScrollArea,
	Card,
	Text,
	Accordion,
	createStyles,
	Group,
} from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { upcomingMeetings } from '../../Atoms/recallAI';
import { BotCreationStatus, BotStatus } from '../../interfaces/recallai';
import UpcomingMeetingItem from './UpcomingMeetingItem';

const useStyles = createStyles((theme) => ({
	accordionControl: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: theme.radius.md,
		fontSize: theme.fontSizes.md,
		fontWeight: 500,
		paddingRight: 0,
		paddingLeft: 0,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	accordionPanel: {},
}));

interface Props {
	openAccordionValue: string;
	setOpenAccordionValue: (value: string) => void;
}

const UpcomingMeetingStack = ({
	openAccordionValue,
	setOpenAccordionValue,
}: Props) => {
	const { classes } = useStyles();
	const meetings = useRecoilValue(upcomingMeetings);
	const upcomingMeetingsWithBots = meetings.filter(
		(meeting) =>
			meeting?.botMetadata?.status !== BotStatus.BotDeleted &&
			meeting.botCreationStatus === BotCreationStatus.CREATED
	);

	if (!upcomingMeetingsWithBots.length) {
		return (
			<Stack w={'100%'} spacing={'sm'}>
				<Title order={6} weight={500}>
					Upcoming
				</Title>
				<Card shadow='md' padding='sm' withBorder radius={'md'}>
					<Text fw={500} fz={'sm'}>
						No upcoming meetings
					</Text>
				</Card>
			</Stack>
		);
	}

	return (
		<>
			{upcomingMeetingsWithBots.length === 1 ? (
				<Stack w={'100%'} spacing={'sm'}>
					<Title order={6} weight={500}>
						Upcoming
					</Title>
					<UpcomingMeetingItem
						key={upcomingMeetingsWithBots[0].id}
						meeting={upcomingMeetingsWithBots[0]}
					/>
				</Stack>
			) : (
				<>
					<Accordion
						value={openAccordionValue}
						onChange={setOpenAccordionValue}
						multiple={false}
						w={'100%'}
						chevron={
							<Text size='xs' color='secondary-blue' w={75} align='right'>
								{openAccordionValue === 'upcoming' ? 'See less' : 'See more'}
							</Text>
						}
						styles={(theme) => ({
							content: {
								paddingLeft: 0,
								paddingTop: 0,
								paddingRight: 0,
							},
							item: {
								borderBottom: 'none',
							},
							chevron: {
								width: 150,
								justifyContent: 'flex-end',
								paddingRight: theme.spacing.xs,
								'&[data-rotate]': {
									transform: 'none',
								},
							},
							control: {
								'&:hover': {
									textDecoration: 'none',
								},
							},
						})}
					>
						<Accordion.Item value={'upcoming'}>
							<Accordion.Control className={classes.accordionControl}>
								<Group position={'apart'} spacing={0}>
									<Group noWrap spacing={0}>
										<Title order={6} weight={500}>
											Upcoming
										</Title>
									</Group>
								</Group>
							</Accordion.Control>
							<Accordion.Panel className={classes.accordionPanel}>
								{upcomingMeetingsWithBots.length ? (
									<ScrollArea
										offsetScrollbars
										scrollbarSize={10}
										style={{ flex: 1, overflowY: 'auto' }}
										styles={{
											viewport: {
												padding: 0,
											},
										}}
									>
										<Stack
											spacing={'sm'}
											justify='flex-end'
											style={{ flex: 1 }}
										>
											{upcomingMeetingsWithBots.map((meeting) => (
												<UpcomingMeetingItem
													key={meeting.id}
													meeting={meeting}
												/>
											))}
										</Stack>
									</ScrollArea>
								) : (
									<Card shadow='md' padding='sm' withBorder radius={'md'}>
										<Text fw={500} fz={'sm'}>
											No meetings for the rest of the day
										</Text>
									</Card>
								)}
							</Accordion.Panel>
						</Accordion.Item>
					</Accordion>
					{upcomingMeetingsWithBots.length &&
						openAccordionValue !== 'upcoming' && (
							<UpcomingMeetingItem
								key={upcomingMeetingsWithBots[0].id}
								meeting={upcomingMeetingsWithBots[0]}
							/>
						)}
				</>
			)}
		</>
	);
};

export default React.memo(UpcomingMeetingStack);
