import React, { useEffect, useState } from 'react';
import ConnectCalendar from '../components/ConnectCalendar';
import { RegisterData, PreferencesData } from '../types';
import OnboardingLayout from '../OnboardingLayout';
import UserInviteSetPreferences from '../components/UserInviteSetPreferences';
import { Text, useMantineTheme } from '@mantine/core';
import RegisterUser from '../components/RegisterUser';
import { useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import {
	user,
	userOrganization,
	userOrganizationMembers,
} from '../../../Atoms/userAtoms';
import { organizationBrand } from '../../../Atoms/settings';
import {
	getCurrentOrganization,
	getCurrentUser,
	getUsersByOrg,
} from '../../../api/api';
import { logger } from '../../../helpers/logger';
import { getOrganizationBrand } from '../../../api/settings';

const SESSION_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours

const NewUsersWithOrg: React.FC = () => {
	const theme = useMantineTheme();

	// Use URLSearchParams directly from window.location.search
	const [searchParams, setSearchParams] = useSearchParams();
	const step = searchParams.get('step') || '1';
	const orgName = searchParams.get('orgName')
		? decodeURIComponent(searchParams.get('orgName')!.replace(/\+/g, ' '))
		: '';

	// Initialize register data directly from URL parameters with decoding
	const initialRegisterData: RegisterData = {
		firstName:
			searchParams.get('firstName') === 'null'
				? ''
				: searchParams.get('firstName') || '',
		lastName:
			searchParams.get('lastName') === 'null'
				? ''
				: searchParams.get('lastName') || '',
		email:
			searchParams.get('email') === 'null'
				? ''
				: searchParams.get('email') || '',
	};

	const [registerData, setRegisterData] =
		useState<RegisterData>(initialRegisterData);
	const [preferencesData, setPreferencesData] = useState<PreferencesData>({
		autoRecord: '',
		postMeetingDistribution: '',
		dailySchedule: null,
	});
	const [organizationID, setOrganizationID] = useState<string | null>(
		searchParams.get('organizationID')
	);
	const setUser = useSetRecoilState(user);
	const setOrganizationMembers = useSetRecoilState(userOrganizationMembers);
	const setBrand = useSetRecoilState(organizationBrand);
	const setOrganization = useSetRecoilState(userOrganization);
	const [authTokenIsSet, setAuthTokenIsSet] = useState(false);

	const handleNext = () => {
		const nextStep = Number(step) + 1;
		searchParams.set('step', nextStep.toString());
		setSearchParams(searchParams);
	};

	const handleGetCurrentUser = async (organizationID = '') => {
		try {
			const user = await getCurrentUser(organizationID);

			setUser(user);

			const { currentOrganizationID } = user;
			const usersResponse = await getUsersByOrg(currentOrganizationID);
			const users = usersResponse;
			setOrganizationMembers(users);

			return user;
		} catch (err) {
			logger('error', 'Error getting current user', err);
		}
	};

	const handleGetCurrentOrganization = async (organizationID: string) => {
		try {
			const currentOrg = await getCurrentOrganization(organizationID);
			setOrganization(currentOrg.data.data);

			// set brand if it exists
			const orgID = currentOrg.data.data.id;
			const brandData = await getOrganizationBrand(orgID);
			if (brandData?.value?.color_primary) {
				setBrand(brandData.value);
				localStorage.setItem('brand', JSON.stringify(brandData.value));
			}

			return currentOrg.data.data;
		} catch (err) {
			logger('error', 'Error getting current organization', err);
		}
	};

	const handleAfterAuthSetup = (organizationID: string) => {
		setTimeout(async () => {
			await Promise.all([
				handleGetCurrentUser(organizationID),
				handleGetCurrentOrganization(organizationID),
			]);
		}, 500);
	};

	useEffect(() => {
		if (authTokenIsSet && organizationID) {
			handleAfterAuthSetup(organizationID);
		}
	}, [authTokenIsSet, organizationID]);

	// Check if session has expired and refresh session start time
	useEffect(() => {
		const sessionStart = sessionStorage.getItem('sessionStart');

		if (step === '1') {
			// Clear everything if on the first step
			sessionStorage.clear();
		} else if (
			sessionStart &&
			Date.now() - parseInt(sessionStart, 10) > SESSION_EXPIRY_TIME
		) {
			// Clear session data if expired
			sessionStorage.clear();
		}

		// Always set a new session start time
		sessionStorage.setItem('sessionStart', Date.now().toString());
	}, [step]);

	// Subtitle component as a function
	const Subtitle = () => (
		<>
			You have been invited to join{' '}
			<Text italic color={theme.fn.primaryColor()} span>
				{orgName}
			</Text>
			. Enter your details to continue:
		</>
	);

	// Render component based on step
	const renderStepComponent = () => {
		switch (step) {
			case '1':
				return (
					<RegisterUser
						onNext={handleNext}
						data={registerData}
						setData={setRegisterData}
						subTitle={<Subtitle />}
						buttonText='Next, tell us about you'
					/>
				);
			case '2':
				return (
					<UserInviteSetPreferences
						onNext={handleNext}
						data={preferencesData}
						setData={setPreferencesData}
						registerData={registerData}
						organizationID={organizationID}
						setAuthTokenIsSet={setAuthTokenIsSet}
					/>
				);
			case '3':
				return (
					<ConnectCalendar
						organizationID={organizationID}
						preferencesData={preferencesData}
					/>
				);
			default:
				return (
					<RegisterUser
						onNext={handleNext}
						data={registerData}
						setData={setRegisterData}
						subTitle={<Subtitle />}
						buttonText='Next, tell us about you'
					/>
				);
		}
	};

	return <OnboardingLayout>{renderStepComponent()}</OnboardingLayout>;
};

export default NewUsersWithOrg;
