import {
	Box,
	Group,
	Text,
	createStyles,
	Grid,
	Paper,
	Button,
	Stack,
} from '@mantine/core';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Logo } from './../../components/reelayLogo';
import Testimonial from './components/Testimonial';
import Gleap from 'gleap';
import useMediaQueries from '../../customHooks/useMediaQueries';
import { useRecoilValue } from 'recoil';
import { testimonialsAtom } from '../../Atoms/testimonialsAtom';
import { useMemo } from 'react';

const useStyles = createStyles((theme) => ({
	container: {
		height: '100vh',
		width: '100vw',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.colors['background-gray'][0],
	},
	header: {
		width: '100%',
		position: 'absolute',
		top: 0,
		paddingLeft: theme.spacing.lg,
		paddingRight: theme.spacing.lg,
		backgroundColor: theme.white,
	},
	link: {
		color: theme.colors.blue[6],
	},
	grid: {
		height: '100%',
		margin: 0,
		padding: 0,
	},
	gridCol: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		height: '100%',
		padding: 0,
		[`@media (max-width: ${theme.breakpoints.md})`]: {
			justifyContent: 'space-between',
			gap: theme.spacing.md,
		},
	},
	paper: {
		width: '90%',
		maxWidth: '1270px',
		height: 'auto',
		minHeight: '500px',
		margin: 0,
		padding: 0,
	},

	stepTrackerWrapper: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
	},
	stepTracker: {
		display: 'flex',
		justifyContent: 'center',
		width: 'auto',
	},
	stepCircle: {
		width: '15px',
		height: '15px',
		borderRadius: '50%',
		border: `1px solid ${theme.fn.primaryColor()}`,
		marginRight: theme.spacing.xs,
	},
	filledCircle: {
		backgroundColor: theme.fn.primaryColor(),
	},
	helpText: {
		fontSize: '16px',
		fontWeight: 500,
		textAlign: 'center',
		color: theme.black,
		cursor: 'pointer', // Add cursor pointer for mobile interaction
		[`@media (max-width: ${theme.breakpoints.xs})`]: {
			color: theme.fn.primaryColor(),
		},
	},
	getHelpButton: {
		marginTop: theme.spacing.md,
	},
}));

const OnboardingLayout: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { classes } = useStyles();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const { maxMediumBreakpoints, isMobile } = useMediaQueries();
	const step = searchParams.get('step') || '1';
	const testimonials = useRecoilValue(testimonialsAtom);
	const randomTestimonial = useMemo(() => {
		return testimonials[Math.floor(Math.random() * testimonials.length)];
	}, [testimonials]);

	// Handle Get Help button (Gleap functionality)
	const handleGetHelp = () => {
		Gleap.openHelpCenter();
	};

	const NEW_ORG_STEPS = 6;
	const DEFAULT_STEPS = 3;

	const getNumberOfSteps = () => {
		if (location.pathname.includes('register')) {
			return NEW_ORG_STEPS;
		}
		return DEFAULT_STEPS;
	};

	// Render step circles based on the current step
	const renderStepCircles = (totalSteps) => {
		const currentStep = Number(step);
		return Array.from({ length: totalSteps }).map((_, index) => (
			<Box
				key={index}
				className={`${classes.stepCircle} ${
					index + 1 < currentStep ? classes.filledCircle : ''
				}`}
				style={{ marginRight: index === totalSteps - 1 ? 0 : undefined }} // Remove marginRight for last circle
			/>
		));
	};

	return (
		<div className={classes.container}>
			{/* Header Section */}
			<Box className={classes.header}>
				<Group position='apart'>
					<Logo />
					<Text
						component='a'
						href='https://reelay.com'
						className={classes.link}
					>
						Back to Reelay.com
					</Text>
				</Group>
			</Box>

			{/* Paper Component wrapping Grid Layout */}
			<Paper shadow='xl' radius='md' className={classes.paper}>
				<Grid align='stretch' className={classes.grid}>
					<Grid.Col
						span={maxMediumBreakpoints ? 12 : 8}
						className={classes.gridCol}
					>
						{children}
					</Grid.Col>
					<Grid.Col span={4} className={classes.gridCol}>
						{!maxMediumBreakpoints && (
							<Testimonial testimonial={randomTestimonial} />
						)}
					</Grid.Col>
				</Grid>
			</Paper>

			{/* Step Tracker and Help Section */}
			<Stack
				align='center'
				mt={maxMediumBreakpoints ? '25px' : '50px'}
				spacing={'xl'}
				mb={isMobile ? '25px' : 0}
			>
				<div className={classes.stepTrackerWrapper}>
					<div className={classes.stepTracker}>
						{renderStepCircles(getNumberOfSteps())}
					</div>
				</div>
				<Stack spacing={0}>
					<Text
						className={classes.helpText}
						onClick={isMobile ? handleGetHelp : undefined}
					>
						Need a human?
					</Text>
					{!isMobile && (
						<Button
							className={classes.getHelpButton}
							onClick={handleGetHelp}
							size='md'
							radius='md'
							w={'200px'}
							h={'50px'}
						>
							Get Help Now
						</Button>
					)}
				</Stack>
			</Stack>
		</div>
	);
};

export default OnboardingLayout;
