import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	SpecialCollections,
	addMeetingToCollectionModalOpenedAtom,
	bulkDeletingCollectionMeetings,
	bulkDeletingCollectionMeetingsSelection,
	editingCollectionAtom,
	renameModalOpenedAtom,
} from '../../../../Atoms/collections';
import {
	ActionIcon,
	Container,
	Group,
	Loader,
	Menu,
	ScrollArea,
	Stack,
	Text,
	Title,
	createStyles,
} from '@mantine/core';
import {
	getCollectionByID,
	getCollectionContent,
	getCollectionUsers,
} from '../../../../api/collections';
import {
	User,
	user,
	userOrganizationMembers,
} from '../../../../Atoms/userAtoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowCircleLeft,
	faCardsBlank,
	faEllipsisVertical,
	faListUl,
	faPlus,
	faTrash,
	faPencil,
	faVideo,
	faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import { keys } from '@mantine/utils';
import MeetingTable from '../../../../components/meeting_table';
import MeetingsCardView from '../../../../components/MeetingsCardView';
import { meetingsView, myMeetings } from '../../../../Atoms/meetingAtom';
import EmptyCollectionPage from './components/EmptyCollectionPage';
import AddMeetingToCollectionModal from '../AddMeetingToCollectionModal/index';
import MeetingSearchBar from './components/MeetingSearchBar';
import RenameCollectionModal from '../RenameCollectionModal';
import { EditScheduledMeetingModal } from '../../../../components/Modals/EditScheduledMeetingModal';
import { useCollections } from '../../../../customHooks/useCollections';
import DeleteCollectionModal from '../DeleteCollectionModal';
import { logger } from '../../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import { CollectionTableProps } from '../CollectionTable';
import { useDisclosure } from '@mantine/hooks';
import { DangerYesButton } from '../../../../components/Buttons/DangerYesButton';
import { DangerNoButton } from '../../../../components/Buttons/DangerNoButton';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import TeamsAvatarGroup from '../../../../components/AvatarGroup';
import CollectionUsersModal from '../CollectionUsersModal';
import { addViewsToMeetings } from '../../../../helpers/meetings';
import AvatarGroup from '../../../../components/AvatarGroup';
import { RowData } from '../../../../components/meeting_table/helper';
import { getEnvForGleap } from '../../../../_utils/trackers';

const useStyles = createStyles(() => ({
	outerContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		// width: '100%',
	},
}));

export default function CollectionPage() {
	const location = useLocation();
	const isArchivePage = location.pathname.includes('archive');
	const { classes } = useStyles();
	const { collectionID } = useParams();
	const navigate = useNavigate();
	const controlsRef = useRef(null);
	const { currentOrganizationID, id } = useRecoilValue(user);
	const allMeetings = useRecoilValue(myMeetings);
	const [viewType, setViewType] = useRecoilState(meetingsView);
	const [loading, setLoading] = useState(true);

	const {
		collection,
		setCollection,
		collections,
		removeCollection,
		bulkRemoveMeetings,
		updateCollectionUsers,
	} = useCollections(isArchivePage);
	const { content, owningUserID, name } = collection;
	const isArchive = name === SpecialCollections.Archived;
	const isCollectionOwner = id === owningUserID;
	const [collectionMeetings, setCollectionMeetings] = useState([]);
	const [meetingCount, setMeetingCount] = useState(0);
	const [opened, setOpened] = useRecoilState(
		addMeetingToCollectionModalOpenedAtom
	);
	const [usersModalOpened, setUsersModalOpened] = useState(false);
	const [deleteModalOpened, { open, close }] = useDisclosure(false);
	const [search, setSearch] = useState('');
	const [sortedData, setSortedData] = useState(collectionMeetings);
	const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
	const [reverseSortDirection, setReverseSortDirection] = useState(false);
	const [renameModalOpened, setRenameModalOpened] = useRecoilState(
		renameModalOpenedAtom
	);
	const [editingCollection, setEditingCollection] = useRecoilState(
		editingCollectionAtom
	);
	const [bulkDeletingMeetings, setBulkDeletingMeetings] = useRecoilState(
		bulkDeletingCollectionMeetings
	);
	const [selection, setSelection] = useRecoilState(
		bulkDeletingCollectionMeetingsSelection
	);

	const organizationMembers = useRecoilValue(userOrganizationMembers);
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		const getCollectionDetails = async () => {
			try {
				let collectionFound;
				if (isArchivePage) {
					collectionFound = collections.find(
						(collection) => collection.name === SpecialCollections.Archived
					);
					if (!collectionFound) {
						const res = await getCollectionByID(
							SpecialCollections.Archived,
							currentOrganizationID
						);
						collectionFound = res;
					}
				} else {
					collectionFound = collections.find(
						(collection) => collection.id === collectionID
					);
					if (!collectionFound) {
						const res = await getCollectionByID(
							collectionID,
							currentOrganizationID
						);
						collectionFound = res;
					}
				}

				// get contents of collection
				const content = await getCollectionContent(collectionFound.id);
				const meetings = content.map((item) => item.meeting);

				// get users of collection
				const users = await getCollectionUsers(collectionFound.id);
				const userList = users
					.map((user: any) => user.user)
					.filter((user) => user);

				const meetingsWithViews = await addViewsToMeetings(meetings);

				setCollection({ ...collectionFound, content, users: userList });
				setCollectionMeetings(meetingsWithViews);
				setSortedData(meetingsWithViews);
			} catch (error) {
				logger('error', 'Error fetching collection details', error);
				showFailureNotification({
					message: 'Error fetching collection details. Please try again.',
				});
			} finally {
				setLoading(false);
			}
		};

		if (collections.length > 0) {
			getCollectionDetails();
		}
	}, [collectionID, isArchivePage, collections, currentOrganizationID]);

	useEffect(() => {
		return () => {
			setSelection([]);
			setBulkDeletingMeetings(false);
		};
	}, []);

	/**
	 * whenever collection's content (meetings) changes,
	 * update collectionMeetings
	 */
	useEffect(() => {
		const meetingIDs = content.map((item) => item.meetingID);
		setCollectionMeetings((prev) =>
			prev.filter((item) => meetingIDs.includes(item.id))
		);
	}, [content]);

	useEffect(() => {
		setSortedData(collectionMeetings);
	}, [collectionMeetings]);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.currentTarget;
		setSearch(value);
		setSortedData(
			sortData(collectionMeetings, {
				sortBy,
				reversed: reverseSortDirection,
				search: value,
			})
		);
	};

	const setSorting = (field: keyof RowData) => {
		const reversed = field === sortBy ? !reverseSortDirection : false;
		setReverseSortDirection(reversed);
		setSortBy(field);
		setSortedData(
			sortData(collectionMeetings, { sortBy: field, reversed, search })
		);
	};

	const sortData = (
		data: RowData[],
		payload: {
			sortBy: keyof RowData | null;
			reversed: boolean;
			search: string;
		}
	) => {
		const { sortBy } = payload;

		if (!sortBy) {
			return filterData(data, payload.search);
		}

		return filterData(
			[...data].sort((a, b) => {
				// sortBy cases to handle
				// need to handle 'host' case using author firstName and lastName
				// need to handle 'viewingDeadline' and 'meetingDate' null values
				const aValue = a[sortBy] ? String(a[sortBy]) : '';
				const bValue = b[sortBy] ? String(b[sortBy]) : '';

				if (payload.reversed) {
					return bValue.localeCompare(aValue);
				}

				return aValue.localeCompare(bValue);
			}),
			payload.search
		);
	};

	const confirmDeleteCollection = async (collection: CollectionTableProps) => {
		try {
			await removeCollection(collection.id, currentOrganizationID);
			navigate('/collections');
		} catch (error) {
			logger('error', 'Error deleting collection', error);
			showFailureNotification({
				message:
					"Sorry, we encountered an error while deleting the collection. Please try again, and if the issue persists, don't hesitate to contact our support team for assistance.",
			});
		} finally {
			close();
		}
	};

	const handleDeleteCollection = (collection: CollectionTableProps) => {
		setEditingCollection(collection);
		open();
	};

	const handleRenameClick = () => {
		setEditingCollection({
			id: collection.id,
			name: collection.name,
			owner: collection.owner,
			lastModified: collection.updatedAt,
		});
		setRenameModalOpened(true);
	};

	function filterData(data: RowData[], search: string) {
		const query = search.toLowerCase().trim();
		return data.filter((item) => {
			return keys(item).some((key) => {
				const value = item[key];
				if (typeof value === 'string') {
					return value.toLowerCase().includes(query);
				} else if (key === 'host') {
					const { firstName, lastName, email } = value as User;
					return (
						firstName?.toLowerCase().includes(query) ||
						lastName?.toLowerCase().includes(query) ||
						email?.toLowerCase().includes(query)
					);
				}
				return false;
			});
		});
	}

	const handleManageMeetings = () => {
		setBulkDeletingMeetings((o) => !o);
	};
	const handleManageUsers = () => {
		setUsersModalOpened(true);
	};

	const confirmCardBulkDelete = async () => {
		try {
			setButtonLoading(true);
			await bulkRemoveMeetings(selection);
			setSelection([]);
		} catch (error) {
			logger('error', 'Error removing meetings from collection', error);
			showFailureNotification({
				message:
					'Sorry, there was an error removing meetings from the collection. Please try again.',
			});
		} finally {
			setButtonLoading(false);
			setBulkDeletingMeetings(false);
		}
	};

	const handleUpdateCollectionUsers = async (values: any) => {
		const newUserIDs = values[1]
			.map((user) => user.value)
			.filter((id) => !collection.users.some((user) => user.id === id));
		const usersToRemove = values[0]
			.map((user) => user.value)
			.filter((id) => collection.users.some((user) => user.id === id));
		try {
			setButtonLoading(true);
			await updateCollectionUsers(newUserIDs, usersToRemove);
			showSuccessNotification({
				title: 'Success!',
				message: "Collection's users was successfully updated! Great work!",
			});
			setUsersModalOpened(false);
		} catch (err: any) {
			logger('error', 'Error updating handleUpdateCollectionUsers', err);
			showFailureNotification({
				message:
					'Sorry, we encountered an error while updating collection users. Please retry the action.',
			});
		} finally {
			setButtonLoading(false);
			setLoading(false);
		}
	};

	return (
		<>
			{/*Modals*/}
			<EditScheduledMeetingModal />
			<RenameCollectionModal />
			<AddMeetingToCollectionModal
				opened={opened}
				setOpened={setOpened}
				rowsPerPage={20}
				meetingCount={meetingCount}
				fetchingMeetings={loading}
				setCollectionMeetings={setCollectionMeetings}
			/>
			<DeleteCollectionModal
				opened={deleteModalOpened}
				close={close}
				collection={editingCollection}
				deleteCollection={confirmDeleteCollection}
			/>
			<CollectionUsersModal
				opened={usersModalOpened}
				setOpened={setUsersModalOpened}
				handleUpdate={handleUpdateCollectionUsers}
				collection={collection}
			/>

			{/*UI*/}
			{loading ? (
				<Stack align={'center'} mt={'xl'}>
					<Loader variant='bars' />
					<Text>Loading...</Text>
				</Stack>
			) : (
				<Container className={classes.outerContainer} size={'xl'} pb={'xl'}>
					<Stack h={'100%'} w={'100%'}>
						<Group position={'apart'} noWrap w={'100%'}>
							<Group
								noWrap
								w={'100%'}
								maw={`calc(100% - ${controlsRef?.current?.clientWidth || 0}px)`}
								style={{ flex: 2 }}
								id={`VIS_ctf9nfdtmsxfgg2vmcrg_${getEnvForGleap()}`}
							>
								<ActionIcon
									m={10}
									variant='transparent'
									onClick={() => navigate('/collections')}
								>
									<FontAwesomeIcon icon={faArrowCircleLeft} size={'xl'} />
								</ActionIcon>
								<Text fz={22} fw={700} truncate>
									{collection?.name}
								</Text>
							</Group>
							<Group
								ref={controlsRef}
								spacing={'xs'}
								noWrap
								id={`VIS_ctf9nfdtmsxfgg2vmcs0_${getEnvForGleap()}`}
							>
								<AvatarGroup users={collection.users} />
								<MeetingSearchBar
									searchValue={search}
									handleSearchChange={handleSearchChange}
									placeholder={'Search inside this collection'}
									style={{ flex: 2 }}
									width={300}
									radius={'lg'}
								/>
								{bulkDeletingMeetings ? (
									<Group spacing={'xs'}>
										<PrimaryButton
											clickFunc={() => setOpened(true)}
											text={'Add'}
											leftIcon={<FontAwesomeIcon icon={faPlus} />}
										/>
										<DangerYesButton
											clickFunc={confirmCardBulkDelete}
											text={'Delete'}
											leftIcon={<FontAwesomeIcon icon={faTrash} />}
											disabled={selection.length === 0}
											loading={buttonLoading}
										/>
										<DangerNoButton
											clickFunc={() => {
												setBulkDeletingMeetings(false);
												setSelection([]);
											}}
											text={'Cancel'}
										/>
									</Group>
								) : null}
								<Menu shadow='md' width={200}>
									<Menu.Target>
										<ActionIcon m={5} variant='transparent'>
											<FontAwesomeIcon icon={faEllipsisVertical} size={'xl'} />
										</ActionIcon>
									</Menu.Target>
									<Menu.Dropdown>
										{viewType === 'card' ? (
											<Menu.Item
												icon={<FontAwesomeIcon icon={faListUl} />}
												onClick={() => setViewType('list')}
											>
												List View
											</Menu.Item>
										) : (
											<Menu.Item
												icon={<FontAwesomeIcon icon={faCardsBlank} />}
												onClick={() => setViewType('card')}
											>
												Card View
											</Menu.Item>
										)}
										{isCollectionOwner ? (
											<>
												<Menu.Item
													icon={<FontAwesomeIcon icon={faPlus} />}
													onClick={() => setOpened(true)}
												>
													Add
												</Menu.Item>
												{!isArchive ? (
													<Menu.Item
														icon={<FontAwesomeIcon icon={faPencil} />}
														onClick={handleRenameClick}
													>
														Rename
													</Menu.Item>
												) : null}
												<Menu.Item
													icon={<FontAwesomeIcon icon={faVideo} />}
													onClick={handleManageMeetings}
												>
													Manage Meetings
												</Menu.Item>
												{!isArchive ? (
													<Menu.Item
														icon={<FontAwesomeIcon icon={faUsers} />}
														onClick={handleManageUsers}
													>
														Manage Users
													</Menu.Item>
												) : null}

												{!isArchive ? (
													<Menu.Item
														icon={<FontAwesomeIcon icon={faTrash} />}
														onClick={() =>
															handleDeleteCollection({
																id: collection.id,
																name: collection.name,
																owner: collection.owner,
																lastModified: collection.updatedAt,
															})
														}
													>
														Remove Collection
													</Menu.Item>
												) : null}
											</>
										) : null}
									</Menu.Dropdown>
								</Menu>
							</Group>
						</Group>
						<ScrollArea style={{ flex: 2 }}>
							{!sortedData.length ? (
								<EmptyCollectionPage
									setOpened={setOpened}
									searching={!!search.length}
									isCollectionOwner={isCollectionOwner}
								/>
							) : viewType === 'card' ? (
								<MeetingsCardView
									meetings={sortedData}
									setMeetings={setSortedData}
									searchValue={search}
									cardsPerPage={20}
									meetingCount={meetingCount}
									loading={loading}
								/>
							) : (
								<MeetingTable
									meetings={sortedData}
									setMeetings={setSortedData}
									search={search}
									rowsPerPage={Infinity}
									meetingCount={meetingCount}
									fetchingMeetings={loading}
									isCollections={true}
									sortData={sortData}
									setSortedData={setSortedData}
									collectionMeetings={collectionMeetings}
									collectionSetSortBy={setSortBy}
									collectionSetReverseSortDirection={setReverseSortDirection}
									collectionReverseSortDirection={reverseSortDirection}
									collectionSortBy={sortBy}
								/>
							)}
						</ScrollArea>
					</Stack>
				</Container>
			)}
		</>
	);
}
