import { Modal, Title, Stack, Group, Button } from '@mantine/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	assignActionModalOpenState,
	assigningActionAtom,
	currentMeetingHighlights,
} from '../../../Atoms/meetingAtom';
import { useEffect, useRef, useState } from 'react';
import { UsersMultiSelect } from '../../UsersMultiSelect';
import { useForm } from '@mantine/form';
import {
	User,
	currentUser,
	userOrganizationMembers,
} from '../../../Atoms/userAtoms';
import { fetchMembers } from '../../../helpers/teams';
import { assign } from 'lodash';
import { assignMeetingActions } from '../../../api/api';
import { logger } from '../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { IAction } from '../../../interfaces/action';
import { IHighlight } from '../../../interfaces/highlight';
import { sortHighlightsByTimeStartMS } from '../../../helpers/highlights';
import { actionTableSelection } from '../../../Atoms/actions';

interface Props {
	refresh?: () => void | null;
	assigningActions?: IAction[];
	setAssigningActions?: (a: IAction[]) => void;
}

export default function AssignActionModal({
	refresh = null,
	assigningActions = [],
	setAssigningActions,
}: Props) {
	const buttonRef = useRef(null);
	const user = useRecoilValue(currentUser);
	const [assignActionModalOpened, setAssignActionModalOpened] = useRecoilState(
		assignActionModalOpenState
	);
	const [loading, setLoading] = useState(false);
	const orgMembers = useRecoilValue(userOrganizationMembers);
	const setSelection = useSetRecoilState(actionTableSelection);
	const [assigningAction, setAssigningAction] =
		useRecoilState(assigningActionAtom);
	const [teamData, setTeamData] = useState<
		{ value: string | string[]; label: string; [key: string]: any }[]
	>(orgMembers || []);
	const alreadyAssignedIDs =
		(assigningAction?.assignees || []).map((a) => a.id) || [];
	const [currentHighlights, setCurrentHighlights] = useRecoilState(
		currentMeetingHighlights
	);

	const form = useForm({
		initialValues: {
			userIDs: [],
		},
		validate: {
			userIDs: (value) =>
				value.length ? null : 'At least one assignee is required.',
		},
	});

	useEffect(() => {
		const fetchMembersData = async () => {
			let members = orgMembers
				.filter((user) => !alreadyAssignedIDs.includes(user.id))
				.map((user: User) => ({
					...user,
					label: `${user.firstName} ${user.lastName} (${user.email})`,
					value: user.id,
					key: user.id,
				}));
			members = await fetchMembers(members, user.currentOrganizationID);
			setTeamData(members);
		};
		fetchMembersData();
	}, [orgMembers, assigningAction]);

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && buttonRef.current) {
			buttonRef.current.click();
		}
	};

	const assignAction = async () => {
		try {
			setLoading(true);
			const { userIDs } = form.values;
			// Parse stringified arrays and flatten
			const processedUserIDs = userIDs.flatMap((userID) => {
				if (typeof userID === 'string') {
					try {
						const parsed = JSON.parse(userID);
						return Array.isArray(parsed) ? parsed : userID;
					} catch {
						return userID;
					}
				}
				return userID;
			});

			// Remove duplicates
			const uniqueUserIDs = Array.from(new Set(processedUserIDs));
			const promises = [];
			for (const userID of uniqueUserIDs) {
				promises.push(
					assignMeetingActions({
						meetingActionIDs: [assigningAction.id],
						userID,
						meetingID: assigningAction.meetingID,
						organizationID: user.currentOrganizationID,
					})
				);
			}

			const res = await Promise.all(promises);
			const updatedHighlights = currentHighlights.map(
				(item: IAction | IHighlight) => {
					if (item.id !== assigningAction.id) return item;
					return {
						...item,
						assignees: [...(item.assignees || []), ...userIDs].filter(
							(v, i, a) => a.findIndex((t) => t.id === v.id) === i
						),
					};
				}
			);
			setCurrentHighlights(sortHighlightsByTimeStartMS(updatedHighlights));
			setAssignActionModalOpened(false);
			form.reset();
			if (refresh !== null) await refresh();
			showSuccessNotification({
				message: 'Action successfully assigned! ',
			});
		} catch (err) {
			logger('error', 'error assigning action', err);
			showFailureNotification({
				message:
					'Sorry, we were unable to assign users to this action. Please try again or contact our support team for assistance.',
			});
		} finally {
			setLoading(false);
		}
	};

	const confirmBulkAssignActions = async () => {
		try {
			setLoading(true);

			const actionsByMeeting = assigningActions.reduce((acc, action) => {
				(acc[action.meetingID] = acc[action.meetingID] || []).push(action.id);
				return acc;
			}, {});

			const { userIDs } = form.values;
			const promises = [];

			for (const [meetingID, actionIDs] of Object.entries(actionsByMeeting)) {
				for (const userID of userIDs) {
					promises.push(
						assignMeetingActions({
							meetingActionIDs: actionIDs as string[],
							userID,
							meetingID,
							organizationID: user.currentOrganizationID,
						})
					);
				}
			}

			const res = await Promise.all(promises);

			await refresh();
			setSelection([]);
			setAssigningActions([]);
			form.reset();
			setAssignActionModalOpened(false);
			showSuccessNotification({
				message: 'Actions successfully assigned!',
			});
		} catch (err) {
			logger('error', 'error assigning action', err);
			showFailureNotification({
				message:
					'Sorry, we were unable to assign users to these actions. Please try again or contact our support team for assistance.',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			size={'sm'}
			p={'md'}
			title={
				<Title order={6} size={'h3'} fw={400}>
					{assigningActions.length
						? `Assign these ${assigningActions.length} actions`
						: `Assign this action`}
				</Title>
			}
			opened={assignActionModalOpened}
			onClose={() => {
				setAssignActionModalOpened(false);
				form.reset();
			}}
			styles={(theme) => ({
				content: {
					borderRadius: theme.spacing.md,
					padding: theme.spacing.xl,
				},
				header: {
					borderRadius: theme.spacing.md,
					paddingBottom: theme.spacing.xl,
				},
			})}
		>
			<Stack>
				<UsersMultiSelect
					// autoFocus={true}
					creatable={true}
					descriptionText={'Add Assignees'}
					variantType={'filled'}
					data={teamData}
					setData={setTeamData}
					clearable={false}
					searchable={true}
					labelText={''}
					placeholder='Add a user.'
					name={'userIDs'}
					form={form}
				/>
				<Group noWrap position={'right'} spacing={'xs'} pb={0}>
					<Button
						variant='subtle'
						radius='md'
						onClick={() => setAssignActionModalOpened(false)}
						px={'sm'}
						disabled={loading}
					>
						Cancel
					</Button>
					<Button
						ref={buttonRef}
						onClick={() =>
							assigningActions.length
								? confirmBulkAssignActions()
								: assignAction()
						}
						radius='md'
						px={'sm'}
						loading={loading}
					>
						Assign
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
