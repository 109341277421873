import { ActionIcon, Indicator } from '@mantine/core';
import { DatePickerInput, DatePickerProps } from '@mantine/dates';
import { Dispatch, SetStateAction, useRef } from 'react';
import { getEnvForGleap } from '../../_utils/trackers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';

interface Props {
	dateRange: [Date | null, Date | null];
	setDateRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
	segmentValue: string;
}

export default function DateRangePicker({
	dateRange,
	setDateRange,
	segmentValue,
}: Props) {
	const dateRef = useRef(null);

	const dayRenderer: DatePickerProps['renderDay'] = (date) => {
		const today = new Date();
		const todayDate = today.getDate();
		const todayMonth = today.getMonth();
		const todayYear = today.getFullYear();

		const day = date.getDate();
		const month = date.getMonth();
		const year = date.getFullYear();

		const isToday =
			day === todayDate && month === todayMonth && year === todayYear;

		return (
			<Indicator size={6} color='red' offset={-5} disabled={!isToday}>
				<div>{day}</div>
			</Indicator>
		);
	};

	const excludeDate = (date: Date): boolean => {
		const today = new Date();
		today.setHours(0, 0, 0, 0); // Normalize today's date to midnight for a fair comparison

		// If segmentValue is 'upcoming', disable all dates before today
		if (segmentValue === 'upcoming') {
			return date < today;
		}

		// If segmentValue is 'live', disable all dates after today
		if (segmentValue === 'live') {
			return date > today;
		}

		return false; // By default, do not disable dates if segmentValue is not 'upcoming' or 'live'
	};

	return (
		<DatePickerInput
			ref={dateRef}
			renderDay={dayRenderer}
			valueFormat='M/D/YYYY'
			allowSingleDateInRange
			variant={'unstyled'}
			type='range'
			clearable
			value={dateRange}
			onChange={(value: [Date | null, Date | null]) => {
				if (Array.isArray(value)) {
					const [start, end] = value;
					if (start) {
						start.setHours(0, 0, 0, 0);
					}
					if (end) {
						end.setHours(23, 59, 59, 999);
					}
					setDateRange([start, end]);
				}
			}}
			excludeDate={(date: Date) => excludeDate(date)}
			icon={
				<ActionIcon variant='transparent'>
					<FontAwesomeIcon icon={faCalendar} color='black' style={{}} />
				</ActionIcon>
			}
			mx='auto'
			maw={400}
			firstDayOfWeek={0}
			styles={{
				wrapper: {
					position: 'relative',
					left: dateRange[0] && dateRange[1] ? '0px' : '3px',
				},
			}}
			id={`VIS_ctf9nfdtmsxfgg2vmcf0_${getEnvForGleap()}`}
		/>
	);
}
