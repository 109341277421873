import React, { useState } from 'react';
import {
	TextInput,
	Button,
	Stack,
	Text,
	Group,
	createStyles,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { RegisterData } from '../types';
import { showFailureNotification } from '../../../helpers/notifications';
import { termsLink } from '../../../components/constants';

const useStyles = createStyles((theme) => ({
	input: {
		backgroundColor: '#F5F5F5',
		borderRadius: theme.radius.sm,
	},
	helpText: {
		fontStyle: 'italic',
		fontSize: 13,
		fontWeight: 400,
		color: theme.fn.primaryColor(),
		textDecoration: 'underline',
		marginTop: theme.spacing.sm,
		width: '100%',
		cursor: 'pointer',
		marginLeft: 'auto',
		marginRight: 'auto',
		[`@media (min-width: ${theme.breakpoints.md}px)`]: {
			width: '70%',
		},
	},
	mainTitle: {
		fontSize: 32,
		fontWeight: 500,
		marginBottom: theme.spacing.lg,
		[`@media (max-width: ${theme.breakpoints.sm})`]: {
			fontSize: 24,
		},
	},
	subTitle: {
		fontSize: 20,
		fontWeight: 500,
		marginBottom: theme.spacing.md,
		[`@media (max-width: ${theme.breakpoints.sm})`]: {
			fontSize: 16,
		},
	},
}));

interface RegisterProps {
	onNext: () => void;
	data: RegisterData;
	setData: (data: RegisterData) => void;
	subTitle: string | JSX.Element;
	buttonText: string;
}

const RegisterUser: React.FC<RegisterProps> = ({
	onNext,
	data,
	setData,
	subTitle,
	buttonText,
}) => {
	const { classes } = useStyles();
	const [loading, setLoading] = useState(false);

	// Initialize Mantine useForm hook with validation
	const form = useForm({
		initialValues: data,
		validate: {
			firstName: (value) =>
				value.trim().length === 0 ? 'First name is required' : null,
			lastName: (value) =>
				value.trim().length === 0 ? 'Last name is required' : null,
			email: (value) =>
				/^\S+@\S+\.\S+$/.test(value) ? null : 'Please provide a valid email',
		},
	});

	const handleSubmit = async (values: RegisterData) => {
		try {
			setLoading(true);
			setData(values);
			onNext();
		} catch (error) {
			showFailureNotification({
				title: 'Failed to create contact',
				message: error?.message || 'An error occurred while creating contact',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Stack spacing='md' p='xl' py={'md'}>
			<Text className={classes.mainTitle}>
				Make every meeting more productive with Reelay.
			</Text>
			<Text className={classes.subTitle}>{subTitle}</Text>

			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Group grow noWrap>
					<TextInput
						placeholder='First Name'
						required
						size='md'
						{...form.getInputProps('firstName')}
						classNames={{ input: classes.input }}
					/>
					<TextInput
						placeholder='Last Name'
						required
						size='md'
						{...form.getInputProps('lastName')}
						classNames={{ input: classes.input }}
					/>
				</Group>

				<TextInput
					placeholder='Email'
					disabled
					type='email'
					required
					size='md'
					{...form.getInputProps('email')}
					classNames={{ input: classes.input }}
					mt='md'
				/>

				<Button
					type='submit'
					fullWidth
					mt='lg'
					size='md'
					radius={'md'}
					loading={loading}
				>
					{buttonText}
				</Button>
			</form>

			<Group w='100%' noWrap>
				<Text
					className={classes.helpText}
					component='a'
					href={termsLink}
					target='_blank'
					rel='noopener noreferrer'
				>
					By clicking next, you agree to Reelay's Terms of Use and Privacy
					Policy.
				</Text>
			</Group>
		</Stack>
	);
};

export default RegisterUser;
