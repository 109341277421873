import { useEffect, useRef, useState } from 'react';
import {
	createStyles,
	Grid,
	Stack,
	Text,
	Title,
	UnstyledButton,
} from '@mantine/core';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUserReelays, user } from '../../Atoms/userAtoms';
import { myMeetings as MyMeetingAtom } from '../../Atoms/meetingAtom';
import {
	getChaptersByMeetingID,
	getHighlights,
	getMeeting,
	searchMeetings,
	sendAnalyticEvent,
} from '../../api/api';
import { IMeeting } from '../../interfaces/meeting';
import ReelayMobilePlayer from '../../components/reelay_mobile_player';
import MobileSummary from '../../components/mobile_summary';
import MobileOverview from '../../components/mobile_overview';
import TimelineBars from '../../components/TimelineBars';
import MobileVideoQueue from '../../components/mobile_video_queue';
import { addViewsToMeetings, Chapter } from '../../helpers/meetings';
import ReelayLogoPageLoader from '../../components/loaders/ReelayLogoPageLoader';
import { useNavigate } from 'react-router';
import { logger } from '../../helpers/logger';
import { sortActionsByTimeStartMS } from '../../helpers/highlights';
import { useMediaQuery } from 'react-responsive';
import { showFailureNotification } from '../../helpers/notifications';
import { isAuthenticated } from '../../Atoms/auth';

const useStyles = createStyles((theme) => ({
	card: {
		padding: 0,
		margin: 0,
	},
	ellipsis: {
		color: theme.colors?.['primary'],
	},
	button: {
		border: `1px solid ${theme.colors.primary[0]}`,
		borderRadius: '.3rem',
		display: 'block',
		padding: '.4rem',
		width: '100%',
		margin: '.1rem',
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		backgroundColor: theme.colors['background-gray'],
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		color: theme.colors['primary-text'],
	},
	outerContainer: {
		height: '100%',
		overflowY: 'hidden',
	},
	bottomContainer: {
		overflowY: 'auto',
		overflow: 'scroll',
	},
}));

const offset = 0;
const order = '-publishedAt';
const status = ['published'];
const isExpired = false;

interface Props {
	setIsUnauthorized?: (isUnauthorized: boolean) => void;
}

export const MobileViewingPage = ({ setIsUnauthorized }: Props) => {
	const { classes, theme } = useStyles();
	const { meetingID } = useParams();
	const navigate = useNavigate();
	const portraitContainerRef = useRef<HTMLDivElement | null>(null);
	const landscapeContainerRef = useRef<HTMLDivElement | null>(null);
	const [meetings, setMeetings] = useRecoilState(currentUserReelays);
	const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	logger('info', 'meetings', meetings);
	const [meeting, setMeeting] = useState(null);
	const setMyMeetings = useSetRecoilState(MyMeetingAtom);
	const currentUser = useRecoilValue(user);
	const [currentHighlights, setHighlights] = useState([]);
	const moments: Chapter[] = currentHighlights.map((hl) => ({
		id: hl.id,
		time: hl.timeStartMS,
		content: hl.content,
		type: hl.type,
	}));

	const [meetingChapters, setMeetingChapters] = useState([]);
	const [loadingMeetings, setLoadingMeetings] = useState(true);
	// const [linksModalOpened, { open, close }] = useDisclosure(false);
	const [searchParams] = useSearchParams();
	const sharingToken = searchParams.get('vt');
	const isAuthorized = useRecoilValue(isAuthenticated);

	useEffect(() => {
		if (isPortrait) {
			const element = portraitContainerRef.current;
			if (element) {
				element.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}
		} else {
			const element = landscapeContainerRef.current;
			if (element) {
				element.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}
		}
	}, [meetingID]);

	/**
	 * Makes a request to the API and loads all the users, meetings in to the view components
	 */
	const handleGetMeetingData = async () => {
		try {
			setLoadingMeetings(true);
			let initialMeeting;

			if (isAuthorized) {
				const response = await searchMeetings(
					{
						offset,
						order,
						status,
						isExpired,
						limit: 100, // no limit for now, future we need to reintroduce infinite scroll + limit.
						organizationID: currentUser.currentOrganizationID,
					},
					null
				);
				const meetings = response.data.data;
				const meetingsWithViews = await addViewsToMeetings(meetings);
				initialMeeting = meetingsWithViews.find(
					(meeting: IMeeting) => meeting.friendlyID === meetingID
				);
				setMyMeetings(meetingsWithViews);
				setMeetings(meetingsWithViews);
			}

			if (!initialMeeting) {
				const meeting = await getMeeting(
					meetingID,
					currentUser.currentOrganizationID,
					sharingToken
				);
				initialMeeting = meeting.data.data;
			}

			setMeeting(initialMeeting);
			await Promise.allSettled([
				handleGetChapters(initialMeeting?.id),
				handleGetHighlights(initialMeeting?.id),
			]);
			return initialMeeting;
		} catch (error) {
			logger('error', 'failed to get meetings', error);
			if (error?.response?.status === 403) setIsUnauthorized(true);
		} finally {
			setLoadingMeetings(false);
		}
	};

	const handleGetChapters = async (meetingID) => {
		try {
			const chapters = await getChaptersByMeetingID(
				meetingID,
				currentUser.currentOrganizationID,
				sharingToken
			);
			const chaptersList = chapters.data.data.chapters;
			setMeetingChapters(chaptersList);
			return chaptersList;
		} catch (error) {
			logger('error', 'failed to get chapters', error);
			setMeetingChapters([]);
		}
	};

	const handleGetHighlights = async (meetingID) => {
		try {
			const highlights = await getHighlights(
				meetingID,
				currentUser.currentOrganizationID,
				sharingToken
			);
			highlights.length
				? setHighlights(sortActionsByTimeStartMS(highlights))
				: setHighlights([]);
		} catch {
			setHighlights([]);
		}
	};

	useEffect(() => {
		const getMeetings = async () => {
			try {
				const res = await handleGetMeetingData();
				const meeting = res;
				if (meeting) {
					sendAnalyticEvent(
						{
							name: 'reelay.video',
							sourceID: meeting?.id,
							targetID: meeting?.videoMetadata?.id,
							data: {
								type: 'meeting opened',
								startTime: 0,
							},
						},
						meeting?.organizationID || meeting?.organizationID,
						sharingToken
					);
				}

				logger('info', 'Meetings loaded for the table', { meetings });
			} catch (error) {
				logger('error', 'Error loading meetings for the table', error);
				showFailureNotification({
					message:
						'Sorry, there was an error loading the meetings. Please try refreshing the page.',
				});
			} finally {
				setLoadingMeetings(false);
			}
		};
		getMeetings();
	}, [meetingID]);

	return (
		<>
			{loadingMeetings ? (
				<ReelayLogoPageLoader caption={'Grabbing your meeting details...'} />
			) : isPortrait ? (
				<Stack spacing={0} className={classes.outerContainer}>
					<Stack spacing={0}>
						<ReelayMobilePlayer
							playbackID={meeting?.videoMetadata?.playbackID}
							meeting={meeting}
						/>
					</Stack>
					<Stack
						ref={portraitContainerRef}
						spacing={0}
						className={classes.bottomContainer}
					>
						<MobileOverview meeting={meeting} setMeeting={setMeeting} />
						<MobileSummary summary={meeting?.summary} />
						{meetingChapters.length ? (
							<TimelineBars
								title={'Topics'}
								isWeb={false}
								items={meetingChapters}
								bulletColor={theme.fn.primaryColor()}
								activeBackgroundColor={'primary.1'}
								meeting={meeting}
							/>
						) : (
							<Stack mx={'lg'} mb={'lg'} spacing={0}>
								<Title size={16}>Chapters</Title>
								<UnstyledButton className={classes.button}>
									<Text size={14} fw={400}>
										Looks like this meeting is missing chapters!
									</Text>
								</UnstyledButton>
							</Stack>
						)}
						{moments.length ? (
							<TimelineBars
								title={'Moments'}
								isWeb={false}
								items={moments}
								bulletColor={theme.fn.primaryColor()}
								activeBackgroundColor={'secondary.1'}
								meeting={meeting}
								isMoments={true}
								type={'moments'}
							/>
						) : (
							<Stack mx={'lg'} mb={'lg'} spacing={0}>
								<Title size={16}>Moments</Title>
								<UnstyledButton className={classes.button}>
									<Text size={14} fw={400}>
										Looks like this meeting is missing moments!
									</Text>
								</UnstyledButton>
							</Stack>
						)}

						{!isAuthorized ? null : (
							<MobileVideoQueue
								viewingRecommendations={true}
								meetings={meetings.filter(
									(meeting) => meeting.friendlyID !== meetingID
								)}
							/>
						)}
					</Stack>
				</Stack>
			) : (
				<Grid className={classes.outerContainer}>
					<Grid.Col
						span={7}
						h={'100%'}
						style={{
							overflowY: 'scroll',
						}}
					>
						<ReelayMobilePlayer
							playbackID={meeting?.videoMetadata?.playbackID}
							meeting={meeting}
						/>
					</Grid.Col>
					<Grid.Col
						ref={landscapeContainerRef}
						span={5}
						h={'100%'}
						style={{
							overflowY: 'scroll',
						}}
					>
						<Stack spacing={0} className={classes.bottomContainer}>
							<MobileOverview meeting={meeting} setMeeting={setMeeting} />
							<MobileSummary summary={meeting?.summary} />
							{meetingChapters.length ? (
								<TimelineBars
									title={'Topics'}
									isWeb={false}
									items={meetingChapters}
									bulletColor={theme.fn.primaryColor()}
									activeBackgroundColor={'primary.1'}
									meeting={meeting}
								/>
							) : (
								<Stack mx={'lg'} mb={'lg'} spacing={0}>
									<Title size={16}>Chapters</Title>
									<UnstyledButton className={classes.button}>
										<Text size={14} fw={400}>
											Looks like this meeting is missing chapters!
										</Text>
									</UnstyledButton>
								</Stack>
							)}
							{moments.length ? (
								<TimelineBars
									title={'Moments'}
									isWeb={false}
									items={moments}
									bulletColor={theme.fn.primaryColor()}
									activeBackgroundColor={'secondary.1'}
									meeting={meeting}
									isMoments={true}
									type={'moments'}
								/>
							) : (
								<Stack mx={'lg'} mb={'lg'} spacing={0}>
									<Title size={16}>Moments</Title>
									<UnstyledButton className={classes.button}>
										<Text size={14} fw={400}>
											Looks like this meeting is missing moments!
										</Text>
									</UnstyledButton>
								</Stack>
							)}

							{!isAuthorized ? null : (
								<MobileVideoQueue
									viewingRecommendations={true}
									meetings={meetings.filter(
										(meeting) => meeting.friendlyID !== meetingID
									)}
								/>
							)}
						</Stack>
					</Grid.Col>
				</Grid>
			)}
		</>
	);
};
