import { ActionIcon, Group, Menu, useMantineTheme } from '@mantine/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import { actionTableSelection } from '../../../Atoms/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionsToggle from '../../../pages/actions/components/ActionsToggle';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import {
	faUserPlus,
	faEnvelope,
	faDownload,
	faTrash,
	faArchive,
	faCheckSquare,
} from '@fortawesome/pro-light-svg-icons';
import { currentUser, isProjectManagerAtom } from '../../../Atoms/userAtoms';
import { getEnvForGleap } from '../../../_utils/trackers';
import { MeetingSearch } from '../../MeetingSearch';
import DateRangePicker from '../../DateRangePicker';
import { isMeetingOwnerOrProjectManager } from '../../../helpers/auth';

interface Props {
	viewType: string;
	setViewType: (value: string) => void;
	searchValue: string;
	handleSearch: (value: string) => void;
	changeSegmentValue: (value: string) => void;
	handleBulkAssign: () => void;
	handleBulkRemind: () => void;
	handleBulkDownload: () => void;
	handleBulkDelete: () => void;
	handleBulkActionableStatus: (actionableStatus: 'todo' | 'archive') => void;
	dateRange: [Date | null, Date | null];
	setDateRange: (dateRange: [Date | null, Date | null]) => void;
}

const SearchActionsBar = ({
	viewType,
	setViewType,
	searchValue,
	handleSearch,
	changeSegmentValue,
	handleBulkAssign,
	handleBulkRemind,
	handleBulkDownload,
	handleBulkDelete,
	handleBulkActionableStatus,
	dateRange,
	setDateRange,
}: Props) => {
	const theme = useMantineTheme();
	const isProjectManager = useRecoilValue(isProjectManagerAtom);
	return (
		<Group
			w={'100%'}
			mt={10}
			maw={1408}
			noWrap
			position={'apart'}
			align={'center'}
			mb={'md'}
		>
			<ActionsToggle
				viewType={viewType}
				setViewType={setViewType}
				changeSegmentValue={changeSegmentValue}
			/>
			<Group noWrap spacing={0} align={'center'} style={{ flex: 2 }}>
				<MeetingSearch
					searchValue={searchValue}
					handleSearch={handleSearch}
					placeholder='Search actions...'
				/>
				<DateRangePicker
					dateRange={dateRange}
					setDateRange={setDateRange}
					segmentValue={'live'}
				/>
				<Menu
					shadow='md'
					width={200}
					id={`VIS_ctf9nfdtmsxfgg2vmceg_${getEnvForGleap()}`}
				>
					<Menu.Target>
						<ActionIcon>
							<FontAwesomeIcon icon={faEllipsisVertical} size={'lg'} />
						</ActionIcon>
					</Menu.Target>
					<Menu.Dropdown>
						<Menu.Label>Bulk Actions</Menu.Label>
						<Menu.Item
							icon={
								<FontAwesomeIcon
									style={{
										color: theme.colors['secondary-text'][0],
										cursor: 'pointer',
									}}
									icon={faUserPlus}
									size={'sm'}
								/>
							}
							onClick={(e) => {
								e.stopPropagation();
								handleBulkAssign();
							}}
						>
							Assign
						</Menu.Item>
						<Menu.Item
							onClick={handleBulkRemind}
							icon={<FontAwesomeIcon icon={faEnvelope} />}
						>
							Remind
						</Menu.Item>

						<Menu.Item
							onClick={handleBulkDownload}
							icon={<FontAwesomeIcon icon={faDownload} />}
						>
							Download as .csv
						</Menu.Item>

						<Menu.Item
							onClick={() =>
								handleBulkActionableStatus(
									viewType === 'todo' ? 'archive' : 'todo'
								)
							}
							icon={
								<FontAwesomeIcon
									icon={viewType === 'todo' ? faArchive : faCheckSquare}
								/>
							}
						>
							{viewType === 'todo' ? 'Archive' : 'Move to Todo'}
						</Menu.Item>

						<Menu.Item
							onClick={handleBulkDelete}
							icon={<FontAwesomeIcon icon={faTrash} />}
						>
							Delete
						</Menu.Item>
					</Menu.Dropdown>
				</Menu>
			</Group>
		</Group>
	);
};

export default SearchActionsBar;
