import { useEffect, useMemo, useRef, useState } from 'react';
import { modals } from '@mantine/modals';
import {
	Alert,
	Button,
	CopyButton,
	Group,
	Loader,
	Paper,
	Select,
	Stack,
	Stepper,
	Switch,
	Text,
	TextInput,
	Title,
	TransferListData,
} from '@mantine/core';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import { saveOrganizationSettings } from '../../../../api/settings';
import {
	currentUser,
	userOrganization,
	userOrganizationMembers,
} from '../../../../Atoms/userAtoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	organizationBrand,
	organizationSettings,
} from '../../../../Atoms/settings';
import { styles } from '../styles';
import OrganizationStep from './components/OrganizationStep/';
import UserRoleStep, {
	activeDirectoryRoles,
} from './components/UserRoleStep/index';
import ConfirmStep from './components/ConfirmStep';
import CompletedStep from './components/CompletedStep';
import {
	assignActiveDirectoryRole,
	getMicrosoftGroups,
	getMicrosoftRoles,
	saveActiveDirectoryGroupsToSync,
	syncActiveDirectoryGroups,
	saveXmlConfigToApi,
	createNewSCIMDirectory,
} from '../../../../api/sso';
import {
	MicrosoftActiveDirectoryGroup,
	MicrosoftActiveDirectoryMember,
} from '../../../../Atoms/sso';
import { logger } from '../../../../helpers/logger';
import { loginWithMicrosoft } from '../../../../helpers/sso';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCircleInfo,
	faCopy,
	faEye,
	faEyeSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { UserRoles } from '../../../../interfaces/user';

const Security = () => {
	const { classes, theme } = styles();
	const user = useRecoilValue(currentUser);
	const [orgSettings, setOrgSettings] = useRecoilState(organizationBrand);
	const [organizationSettingsValue, setOrganizationSettings] =
		useRecoilState(organizationSettings);
	const orgUsers = useRecoilValue(userOrganizationMembers);
	const organization = useRecoilValue(userOrganization);
	const headerRef = useRef(null);
	const [rolesState, setRolesState] = useState<{ [key: string]: string }>({});
	const [activeDirectoryGroups, setActiveDirectoryGroups] = useState<{
		[key: string]: MicrosoftActiveDirectoryGroup;
	}>({});
	// const [roles, setRoles] = useState<MicrosoftActiveDirectoryRoles[]>([]);
	const [userList, setUserList] = useState<MicrosoftActiveDirectoryMember[]>(
		[]
	);
	const [checked, setChecked] = useState(false);
	const [loading, setLoading] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [data, setData] = useState<TransferListData>([[], []]);
	const [step, setStep] = useState(0);
	const LAST_STEP = 3;

	const [scimChecked, setScimChecked] = useState(
		organizationSettingsValue?.scim_enabled || false
	);
	const [fallbackTransferee, setFallbackTransferee] = useState('');
	const [scimSecretValues, setScimSecretValues] = useState({
		endpoint: '',
		secret: '',
	});

	const [visibleKeys, setVisibleKeys] = useState<{ [key: string]: boolean }>({
		endpoint: true,
		secret: false,
	});
	const isSuperAdmin = user.roles.includes(UserRoles.SuperAdmin);

	const toggleVisibility = (key: string) => {
		setVisibleKeys((prev) => ({ ...prev, [key]: !prev[key] }));
	};

	const superadminUsers = useMemo(() => {
		return orgUsers.filter((user) => user.roles.includes('superadmin'));
	}, [orgUsers]).map((user) => ({
		...user,
		value: user.id,
		label: `${user.firstName} ${user.lastName} (${user.email})`,
	}));

	const nextStep = async () => {
		try {
			setButtonLoading(true);
			if (step === 0) {
				const groupIds = data[1].map((item) => item.value);
				const res = await saveActiveDirectoryGroupsToSync(groupIds);
				const syncRes = await syncActiveDirectoryGroups();
				if (res?.name === 'AxiosError') {
					throw new Error(res?.message);
				}
				if (syncRes?.name === 'AxiosError') {
					throw new Error(syncRes?.message);
				}
			} else if (step === 1) {
				const users = Object.keys(rolesState).map((userID) => {
					return [userID, rolesState[userID].split(' ')[1].toLowerCase()];
				});
				const [superadminUserIDs, adminUserIDs, memberUserIDs] = users.reduce(
					(acc, [userID, role]) => {
						if (role === 'superadmins') {
							return [[...acc[0], userID], acc[1], acc[2]];
						} else if (role === 'admins') {
							return [acc[0], [...acc[1], userID], acc[2]];
						} else {
							return [acc[0], acc[1], [...acc[2], userID]];
						}
					},
					[[], [], []]
				);
				const promiseArray = [];
				if (superadminUserIDs.length) {
					promiseArray.push(
						assignActiveDirectoryRole(superadminUserIDs, 'superadmin')
					);
				}
				if (adminUserIDs.length) {
					promiseArray.push(assignActiveDirectoryRole(adminUserIDs, 'admin'));
				}
				if (memberUserIDs.length) {
					promiseArray.push(assignActiveDirectoryRole(memberUserIDs, 'member'));
				}
				const res = await Promise.allSettled(promiseArray);
				const failedResponse = res.find(
					(item: PromiseSettledResult<{ name?: string; message?: string }>) =>
						item?.status === 'fulfilled' && item?.value?.name === 'AxiosError'
				) as
					| PromiseSettledResult<{ name?: string; message?: string }>
					| undefined;
				if (failedResponse) {
					if (failedResponse?.status === 'fulfilled') {
						throw new Error(failedResponse.value.message);
					}
				}
			}
			setStep((current) => (current < LAST_STEP ? current + 1 : current));
		} catch (err) {
			logger('error', err?.message, err);
			const message =
				step === 0
					? "Apologies, we couldn't save the groups. Please try again or contact our support team for assistance."
					: 'Apologies, role assignment failed. Please try again or reach out to our support team for assistance.';
			showFailureNotification({ message });
		} finally {
			setButtonLoading(false);
		}
	};
	const prevStep = () =>
		setStep((current) => (current > 0 ? current - 1 : current));

	useEffect(() => {
		const getOrganizationGroups = async () => {
			setChecked(orgSettings?.sso);
			try {
				setLoading(true);
				if (orgSettings?.sso) {
					const groups = await getMicrosoftGroups();
					setActiveDirectoryGroups(
						groups.reduce((acc, group) => {
							return {
								...acc,
								[group.id]: group,
							};
						}, {})
					);

					const unsyncedGroups = groups
						.filter((group) => !group.synced)
						.map((group) => ({
							value: group.id,
							label: group.displayName,
						}));
					const syncedGroups = groups
						.filter((group) => group.synced)
						.map((group) => ({
							value: group.id,
							label: group.displayName,
						}));

					setData([unsyncedGroups, syncedGroups]);
					await getMicrosoftRoles();
				} else {
					setStep(0);
				}
			} catch (err) {
				logger('error', err?.message, err);
			} finally {
				setLoading(false);
			}
		};

		getOrganizationGroups();
	}, [orgSettings?.sso]);

	const setupRoleState = (data) => {
		setRolesState(
			data.reduce((acc, item) => {
				return {
					...acc,
					[item.id]: item?.roles?.some(
						(role) => role.displayName === activeDirectoryRoles.superadmin
					)
						? activeDirectoryRoles.superadmin
						: item?.roles?.some(
								(role) => role.displayName === activeDirectoryRoles.admin
						  )
						? activeDirectoryRoles.admin
						: activeDirectoryRoles.member,
				};
			}, {})
		);
	};

	useEffect(() => {
		const redirectUrl = localStorage.getItem(
			'microsoft_sso_setup_redirect_url'
		);
		if (redirectUrl) {
			handleSSO(true);
			localStorage.removeItem('microsoft_sso_setup_redirect_url');
		}
	}, []);

	useEffect(() => {
		if (step === 1) {
			setLoading(true);
			const userList = buildUserList(data[1].map((group) => group.value));
			!Object.keys(rolesState).length && setupRoleState(userList);
			setUserList(userList);
			setLoading(false);
		}
	}, [step]);

	useEffect(() => {
		if (step === 0) {
			setRolesState({});
		}
	}, [data]);

	const buildUserList = (groupIDs: string[]) => {
		let users = [];
		groupIDs.forEach((groupID) => {
			const group = activeDirectoryGroups[groupID];
			const membersWithDirectoryName = group.members.map((member) => ({
				...member,
				directory: group.displayName,
			}));
			users = [...users, ...membersWithDirectoryName];
		});
		return users;
	};

	const turnOnSSO = async () => {
		localStorage.setItem(
			'microsoft_sso_setup_redirect_url',
			'/settings/security'
		);
		loginWithMicrosoft();
	};

	const handleSSO = async (value: boolean) => {
		try {
			// toggle on and off SSO for a organization setting.
			const payload = [
				{
					key: 'brand',
					value: {
						name: 'SSO',
						sso: value,
					},
				},
			];
			await saveOrganizationSettings(payload, user.currentOrganizationID);
			// await ensureActiveDirectoryCustomRolesExist();

			setOrgSettings((prev) => ({
				...prev,
				// [sso.key]: sso.value,
				sso: value,
			}));
			setChecked(value);
		} catch (err) {
			logger('error', err?.message, err);
		}
	};

	const openConfirmDisableSSOModal = () =>
		modals.openConfirmModal({
			title: 'Please confirm your action',
			children: (
				<Text size='sm'>
					Turning off SSO will disable all the user accounts tied to your active
					directories. These users will be set to inactive and no longer be able
					to log in using the SSO option. Please click confirm to proceed.
				</Text>
			),
			labels: { confirm: 'Confirm', cancel: 'Cancel' },
			onConfirm: () => handleSSO(false),
		});

	const isNextButtonDisabled = () => {
		switch (step) {
			case 0:
				return !data[1].length;
		}
	};

	const userListTableData = userList.map(
		({
			id,
			givenName,
			surname,
			mail,
			userPrincipalName,
			directory,
			userType,
		}) => {
			const name =
				givenName === null && surname === null
					? 'No name in AD.'
					: `${givenName === null ? '' : givenName} ${
							surname === null ? '' : surname
					  }`;
			return {
				id,
				name,
				email: mail ?? userPrincipalName ?? id,
				directory,
				userType,
			};
		}
	);

	const form = useForm({
		initialValues: {
			fallbackTransfereeUserID: '',
		},
		validate: {
			fallbackTransfereeUserID: (value) =>
				value.length > 0 ? null : 'Fallback transferee is required',
		},
	});

	const samlForm = useForm({
		initialValues: {
			config: '',
		},
		validate: {
			config: (value) => (value.length > 0 ? null : 'Config is required'),
		},
	});

	useEffect(() => {
		if (fallbackTransferee)
			form.setFieldValue('fallbackTransfereeUserID', fallbackTransferee);

		if (organizationSettingsValue?.fallback_transferee) {
			setFallbackTransferee(organizationSettingsValue?.fallback_transferee);
		}
	}, [fallbackTransferee, organizationSettingsValue]);

	const handleSCIMToggle = async (value: boolean) => {
		try {
			setScimChecked(value);
			if (value) {
				// Step 1: Create new SCIM directory
				const response = await createNewSCIMDirectory(
					organization.name,
					organization.id,
					user.id
				);

				// Step 4: Set the user to the new SCIM directory default user returned from response
				const { scim, fallbackTransferee: fallbackUser } = response;
				setScimSecretValues({
					endpoint: scim.endpoint,
					secret: scim.secret,
				});
				setFallbackTransferee(fallbackUser.stringValue);

				// Step 3: Show success notification
				showNotification({
					title: 'Success',
					message: 'SCIM directory created successfully',
					color: 'green',
				});
			}

			const payload = [
				{
					key: 'scim_enabled',
					value,
				},
			];
			const res = await saveOrganizationSettings(payload, organization.id);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
		} catch (error) {
			console.error('Error creating SCIM directory:', error);
			showNotification({
				title: 'Error',
				message: 'Failed to create SCIM directory',
				color: 'red',
			});
		}
	};

	const saveFallbackTransferee = async (values) => {
		try {
			const { fallbackTransfereeUserID } = values;

			const payload = [
				{
					key: 'fallback_transferee',
					value: fallbackTransfereeUserID,
				},
			];
			const res = await saveOrganizationSettings(payload, organization.id);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
			showSuccessNotification({
				title: 'Success',
				message: 'Fallback transferee saved.',
			});
		} catch (err) {
			console.error('Error saving fallback transferee:', err);
			showNotification({
				title: 'Error',
				message: 'Failed to save fallback transferee.',
				color: 'red',
			});
		}
	};

	const saveConfig = async (values) => {
		const { config } = values;
		const saveXmlConfigResult = await saveXmlConfigToApi(config);
		if (saveXmlConfigResult.success) {
			showSuccessNotification({
				title: 'Success',
				message: 'SAML config saved',
			});
		} else
			showFailureNotification({
				title: 'Somthing went wrong',
				message: saveXmlConfigResult.message,
			});
	};

	return (
		<Stack p={0} h={'100%'}>
			<Title ref={headerRef} order={3}>
				Security
			</Title>
			<Stack
				style={{
					flex: 2,
					height: `calc(100% - ${
						headerRef?.current?.offsetHeight || 0
					}px - 24px)`,
					overflowY: 'hidden',
				}}
			>
				<Paper
					className={classes.card}
					shadow='xs'
					p='md'
					radius={'md'}
					h={'auto'}
					mah={'100%'}
					w={'100%'}
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{/*<Stack spacing={'xl'} h={checked ? '100%' : 'auto'}>*/}
					<Switch
						labelPosition='left'
						label='Single Sign On (SSO)'
						description='Enabling this allows users in your organization to sign in through other applications such as Google, Microsoft, and Facebook.'
						size='sm'
						w={'100%'}
						width={'100%'}
						mb={'lg'}
						checked={checked}
						onChange={(e) => {
							const value = e.currentTarget.checked;
							value ? turnOnSSO() : openConfirmDisableSSOModal();
						}}
						styles={{
							body: {
								width: '100%',
								justifyContent: 'space-between',
								flex: 1,
							},
						}}
					/>
					{orgSettings.sso ? (
						<Stack
							style={{ flex: 2, overflowY: 'hidden' }}
							mah={`calc(100% - 76px)`}
						>
							{/*<Stack h={`calc(100% - 76px)`}>*/}
							<Stepper
								active={step}
								onStepClick={setStep}
								allowNextStepsSelect={false}
								size={'sm'}
								styles={{
									root: {
										flex: 1,
									},
								}}
							>
								<Stepper.Step label='First step' description='Select groups'>
									Step 1: Select the groups you want to sync
								</Stepper.Step>
								<Stepper.Step label='Second step' description='Verify roles'>
									Step 2: Verify user roles
								</Stepper.Step>
								<Stepper.Step label='Final step' description='Review'>
									Step 3: Review sync
								</Stepper.Step>
								<Stepper.Completed>
									Completed. The users a part of the active directories you've
									added will be able to login using single sign-on.
									<CompletedStep setStep={setStep} />
								</Stepper.Completed>
							</Stepper>
							{loading ? (
								<Stack style={{ flex: 3 }} align={'center'}>
									<Loader size={'xl'} />
								</Stack>
							) : step === 0 ? (
								<OrganizationStep data={data} setData={setData} />
							) : step === 1 ? (
								<UserRoleStep
									data={userListTableData}
									rolesState={rolesState}
									setRolesState={setRolesState}
								/>
							) : step === 2 ? (
								<ConfirmStep
									data={data}
									setStep={setStep}
									userListTableData={userListTableData}
									activeDirectoryGroups={activeDirectoryGroups}
									rolesState={rolesState}
									setRolesState={setRolesState}
								/>
							) : null}
							{step !== LAST_STEP ? (
								<Group position='center' style={{ flex: 1 }}>
									{step !== 0 ? (
										<Button variant='default' onClick={prevStep}>
											Back
										</Button>
									) : null}
									<Button
										loading={buttonLoading}
										disabled={isNextButtonDisabled()}
										onClick={nextStep}
									>
										Next
									</Button>
								</Group>
							) : null}
						</Stack>
					) : null}
				</Paper>
				{isSuperAdmin ? (
					<>
						<Paper
							className={classes.card}
							shadow='xs'
							p='md'
							radius={'md'}
							h={'auto'}
							mah={'100%'}
							w={'100%'}
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Switch
								labelPosition='left'
								label='Directory Sync (SCIM)'
								description='Set up a SCIM directory for your organization.'
								size='sm'
								w={'100%'}
								width={'100%'}
								mb={'lg'}
								checked={scimChecked}
								onChange={(event) =>
									handleSCIMToggle(event.currentTarget.checked)
								}
								styles={{
									body: {
										width: '100%',
										justifyContent: 'space-between',
										flex: 1,
									},
								}}
							/>
							{scimChecked ? (
								<form
									onSubmit={form.onSubmit((values) =>
										saveFallbackTransferee(values)
									)}
								>
									<Stack>
										<Alert
											radius='md'
											icon={<FontAwesomeIcon icon={faCircleInfo} />}
											title='SCIM Secret Keys'
											styles={{
												root: {
													backgroundColor: theme.colors['light-blue'][0],
													// borderColor: '#FFD60A',
												},
											}}
										>
											{Object.values(scimSecretValues).some(
												(value) => value === ''
											) ? (
												<Text fz={12}>
													SCIM secret keys are only available upon creation. If
													you would like to generate new keys, turn off then
													back on the directory sync.
												</Text>
											) : (
												<Stack spacing={'xs'}>
													{['endpoint', 'secret'].map((key) => (
														<Group key={key} position='apart' noWrap>
															<Group
																style={{
																	flex: 2,
																}}
																noWrap
															>
																<Text fz={12} fw={500} miw={75}>
																	{`${
																		key.charAt(0).toUpperCase() + key.slice(1)
																	}: `}
																</Text>
																<Text fz={12}>
																	{visibleKeys[key]
																		? scimSecretValues[key]
																		: '•••••••••••••'}
																</Text>
															</Group>
															<Group noWrap>
																<FontAwesomeIcon
																	style={{
																		color: theme.colors['secondary-text'][0],
																		cursor: 'pointer',
																		marginRight: '8px',
																	}}
																	icon={visibleKeys[key] ? faEyeSlash : faEye}
																	size={'sm'}
																	onClick={() => toggleVisibility(key)}
																/>
																<CopyButton
																	value={scimSecretValues[key]}
																	timeout={2000}
																>
																	{({ copy }) => (
																		<FontAwesomeIcon
																			style={{
																				color:
																					theme.colors['secondary-text'][0],
																				cursor: 'pointer',
																			}}
																			icon={faCopy}
																			size={'sm'}
																			onClick={(e) => {
																				e.stopPropagation();
																				showNotification({
																					title: 'Success',
																					message:
																						'SCIM secret copied to clipboard!',
																					color: 'green',
																				});
																				copy();
																			}}
																		/>
																	)}
																</CopyButton>
															</Group>
														</Group>
													))}
												</Stack>
											)}
										</Alert>
										<Select
											label=''
											placeholder='Select fallback transferee'
											description='This is the user where meetings will be transferred to if a user within saml is deleted.'
											data={superadminUsers}
											{...form.getInputProps('fallbackTransfereeUserID')}
											inputWrapperOrder={['input', 'error', 'description']}
										/>
										<Group position='right'>
											<Button type='submit' mt='md'>
												Save
											</Button>
										</Group>
									</Stack>
								</form>
							) : null}
						</Paper>
						<Paper
							className={classes.card}
							shadow='xs'
							p='md'
							radius={'md'}
							h={'auto'}
							mah={'100%'}
							w={'100%'}
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Text fz={14} color='black'>
								SAML Config
							</Text>
							<Text fz={12} mb={'md'} color='#868e96'>
								Add an XML configuration to SAML.
							</Text>
							<form
								onSubmit={samlForm.onSubmit((values) => saveConfig(values))}
							>
								<Stack>
									<TextInput
										placeholder='Enter XML config here'
										{...samlForm.getInputProps('config')}
										inputWrapperOrder={['input', 'error', 'description']}
									/>
									<Group position='right'>
										<Button type='submit' mt='md'>
											Submit
										</Button>
									</Group>
								</Stack>
							</form>
						</Paper>
					</>
				) : null}
			</Stack>
		</Stack>
	);
};

export default Security;
