import React, { useRef, useState } from 'react';
import {
	Button,
	Group,
	Modal,
	Stack,
	TextInput,
	Title,
	Text,
} from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { logger } from '../../helpers/logger';
import { currentUser, userOrganizationMembers } from '../../Atoms/userAtoms';
import {
	getUsersByOrg,
	inviteUsersToMeeting,
	registerUser,
} from '../../api/api';
import {
	attendeesList,
	currentMeeting,
	currentMeetingDistributionList,
} from '../../Atoms/meetingAtom';
import { IMeeting } from '../../interfaces/meeting';

interface Props {
	setOpened: React.Dispatch<React.SetStateAction<boolean>>;
	opened: boolean;
	newUserValues: {
		firstName: string;
		lastName: string;
		email: string;
	};
	meeting: IMeeting;
}

export default function AddUserModal({
	opened,
	setOpened,
	newUserValues,
	meeting,
}: Props) {
	const focusTrapRef = useFocusTrap();
	const buttonRef = useRef(null);
	const setDistributionList = useSetRecoilState(currentMeetingDistributionList);
	const setAttendees = useSetRecoilState(attendeesList);
	const setMembers = useSetRecoilState(userOrganizationMembers);
	const { id, organizationID } = meeting;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [value, setValue] = useState('');

	const handleInviteUser = async () => {
		try {
			if (!value.length) {
				setError('Email is required');
				return;
			}

			setLoading(true);

			try {
				// register user
				const newUser = await registerUser({
					email: value,
					firstName: newUserValues.firstName,
					lastName: newUserValues.lastName,
				});
				logger('info', 'User successfully invited', newUser);
			} catch {
				console.log('User already exists');
			}

			// invite to meeting
			const invitedUser = await inviteUsersToMeeting({
				emails: [value],
				meetingID: id,
				organizationID,
			});
			// need to update viewers list with new users.
			setDistributionList((prev) => {
				const result = [...prev, ...invitedUser.data.data].filter(
					(item, index, array) => {
						return array.findIndex((i) => i.id === item.id) === index;
					}
				);
				return result;
			});
			setAttendees((prev) =>
				prev.map((user) => {
					return user[1] === newUserValues.firstName &&
						user[2] === newUserValues.lastName
						? invitedUser.data.data[0]
						: user;
				})
			);

			const members = await getUsersByOrg(organizationID);
			setMembers(members);
			showSuccessNotification({
				message:
					'Attendee was successfully invited to this meeting. Great work!',
			});
			setOpened(false);
			setValue('');
			setError(null);
		} catch (error) {
			logger('error', 'Error inviting user', error);
			showFailureNotification({
				message:
					'Sorry, there was an error when inviting a user. Please try again.',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && buttonRef.current) {
			buttonRef.current.click();
		}
	};

	return (
		<Modal
			size={'sm'}
			centered={true}
			title={
				<Title order={6} size={'h3'} fw={400}>
					Grant Access
				</Title>
			}
			opened={opened}
			onClose={() => setOpened(false)}
			zIndex={2000}
		>
			<Stack ref={focusTrapRef}>
				<Text>
					Oops! It looks like we do not have an email for this meeting attendee.
					Don't worry, you can still share this by entering their email below
				</Text>
				<TextInput
					data-autofocus
					placeholder={'Attendee email'}
					value={value}
					onChange={(event) => setValue(event.currentTarget.value)}
					disabled={loading}
					error={error}
					onKeyDown={handleKeyPress}
				/>
				<Group noWrap position={'right'} spacing={'xs'}>
					<Button
						variant='subtle'
						radius='md'
						onClick={() => setOpened(false)}
						px={'sm'}
						disabled={loading}
					>
						Cancel
					</Button>
					<Button
						ref={buttonRef}
						onClick={handleInviteUser}
						variant='subtle'
						radius='md'
						px={'sm'}
						loading={loading}
					>
						Share
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
