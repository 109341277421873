import { SegmentedControl, Group, Center, Box } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faListUl,
	faCardsBlank,
	faCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { getEnvForGleap } from '../../../../_utils/trackers';

interface Props {
	viewType: string;
	setViewType: (value: string) => void;
	changeSegmentValue: (value: string) => void;
}

export default function ActionsToggle({
	viewType,
	setViewType,
	changeSegmentValue,
}: Props) {
	return (
		<Group
			position='center'
			id={`VIS_ctf9nfdtmsxfgg2vmce0_${getEnvForGleap()}`}
		>
			<SegmentedControl
				value={viewType}
				onChange={(value) => changeSegmentValue(value)}
				data={[
					{
						value: 'todo',
						label: (
							<Center>
								<FontAwesomeIcon icon={faListUl} />
								<Box ml={10}>To do</Box>
							</Center>
						),
					},
					{
						value: 'archive',
						label: (
							<Center>
								<FontAwesomeIcon icon={faCheck} />
								<Box ml={10}>Archived</Box>
							</Center>
						),
					},
				]}
			/>
		</Group>
	);
}
