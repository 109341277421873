'use client';

import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Center, Loader, createStyles, Box } from '@mantine/core';
import { LoginForm } from './components/LoginForm';
import { Footer } from './components/Footer';
import { Logo } from '../../components/reelayLogo';
import {
	authTokenExpirationTime,
	isAuthenticated,
	originalURL,
	reelayLoader,
	userAuthenticated as userAuthenticatedAtom,
} from '../../Atoms/auth';
import {
	currentUser,
	user as UserAtom,
	userOrganizationMembers,
	userOrganization as OrganizationAtom,
} from '../../Atoms/userAtoms';
import { isCustomBrandedAtom, organizationBrand } from '../../Atoms/settings';
import { useLoginLogic } from '../../customHooks/useLoginLogic';
import { getOrganizationBrand } from '../../api/settings';
import { testimonialsAtom } from '../../Atoms/testimonialsAtom';

const useStyles = createStyles((theme) => ({
	page: {
		minHeight: '100vh',
		display: 'flex',
	},
	leftPanel: {
		display: 'none',
		[`@media (min-width: ${theme.breakpoints.lg})`]: {
			display: 'flex',
			width: '50%',
			padding: '32px',
			flexDirection: 'column',
			justifyContent: 'space-between',
			position: 'relative',
			background:
				theme.fn.primaryColor() === '#3b368f'
					? theme.fn.linearGradient(
							180,
							'hsl(0, 0%, 100%)',
							'hsl(273, 79%, 26%, 0.3)',
							'hsl(273, 79%, 26%, 0.8)'
					  )
					: theme.fn.linearGradient(
							180,
							'hsl(0, 0%, 100%)',
							theme.colors[theme.primaryColor][3],
							theme.colors[theme.primaryColor][7]
					  ),
		},
	},
	rightPanel: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '32px',
	},
	reelayLink: {
		color: theme.black,
		textDecoration: 'none',
		fontSize: theme.fontSizes.sm,
		fontWeight: 500,
		'&:hover': {
			color: theme.colors.gray[6],
		},
	},
	content: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0 32px',
	},
	logo: {
		position: 'relative',
		bottom: '15px',
	},
	testimonial: {
		zIndex: 10,
		color: 'white',
		fontSize: theme.fontSizes.md,
		fontWeight: 500,
		'& blockquote': {
			marginBottom: theme.spacing.md,
			marginInlineStart: 0,
		},
	},
	rightPanelLogo: {
		[`@media (min-width: ${theme.breakpoints.lg})`]: {
			display: 'none',
		},
	},
}));

export default function LoginPage() {
	const { classes, theme } = useStyles();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [pageLoading, setPageLoading] = useState(true);
	const [loggingIn, setLoggingIn] = useRecoilState(reelayLoader);
	const [loading, setLoading] = useState(false);
	const [brand, setBrand] = useRecoilState(organizationBrand);
	const originalUrl = useRecoilValue(originalURL);
	const user = useRecoilValue(currentUser);
	const userAuthenticated = useRecoilValue(isAuthenticated);
	const isCustomBranded = useRecoilValue(isCustomBrandedAtom);
	const testimonials = useRecoilValue(testimonialsAtom);
	const randomTestimonial = useMemo(() => {
		return testimonials[Math.floor(Math.random() * testimonials.length)];
	}, [testimonials]);

	const {
		handleLoginLogic,
		handleSendAuthCode,
		handleAuthCode,
		handleGetCurrentUser,
		handleGetCurrentOrganization,
		loginUser,
		loginWithMicrosoft,
		checkAuthAndRedirect,
	} = useLoginLogic({
		setLoggingIn,
		setLoading,
		setAuthStatus: useSetRecoilState(userAuthenticatedAtom),
		setAuthTokenExpirationTime: useSetRecoilState(authTokenExpirationTime),
		setCurrentUser: useSetRecoilState(UserAtom),
		setOrganization: useSetRecoilState(OrganizationAtom),
		setOrganizationMembers: useSetRecoilState(userOrganizationMembers),
		navigate,
	});

	useEffect(() => {
		const getBrand = async () => {
			try {
				const organizationID = searchParams.get('organizationID');
				let data;
				if (organizationID) {
					data = await getOrganizationBrand(organizationID);
					setBrand(data?.value);
				}
			} catch (error) {
				console.error('Error fetching brand data:', error);
			} finally {
				setPageLoading(false);
			}
		};

		getBrand();
	}, [searchParams]);

	useEffect(() => {
		checkAuthAndRedirect(userAuthenticated, user, originalUrl);
	}, [userAuthenticated, user]);

	return (
		<>
			{pageLoading ? (
				<Center className={classes.page}>
					<Loader size={200} />
				</Center>
			) : (
				<div className={classes.page}>
					{/* Left Panel */}
					<Box className={classes.leftPanel}>
						<div className={classes.logo}>
							<Logo height={30} width={120} />
						</div>
						<div className={classes.testimonial}>
							<blockquote>{randomTestimonial?.testimonial}</blockquote>
							<cite>{randomTestimonial?.name}</cite>
						</div>
					</Box>

					{/* Right Panel */}
					<div className={classes.rightPanel}>
						<div className={classes.header}>
							<Box className={classes.rightPanelLogo}>
								<Logo height={30} width={120} />
							</Box>
							<a
								href='https://reelay.com'
								className={classes.reelayLink}
								target='_blank'
								rel='noopener noreferrer'
							>
								Visit reelay.com
							</a>
						</div>
						<div className={classes.content}>
							{!loggingIn && (
								<LoginForm
									loginWithMicrosoft={loginWithMicrosoft}
									logo={brand?.logo_url}
									primaryColor={brand?.color_primary || '#4a0e78'}
									secondaryColor={brand?.color_secondary || '#8e44ad'}
									handleSendAuthCode={handleSendAuthCode}
									handleAuthCode={handleAuthCode}
								/>
							)}
						</div>
						<Footer
							version={process.env.REACT_APP_REELAY_VERSION || '3.0.0'}
							isCustomBranded={isCustomBranded}
						/>
					</div>
				</div>
			)}
		</>
	);
}
