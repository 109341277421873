import {
	ActionIcon,
	Button,
	Group,
	Paper,
	Popover,
	Select,
	Stack,
	Switch,
	Text,
	Tooltip,
	createStyles,
} from '@mantine/core';
import PlatformIcon from '../../PlatformIcon';
import { IMeeting } from '../../../interfaces/meeting';
import { useEffect, useState } from 'react';
import { logger } from '../../../helpers/logger';
import { DateTime } from 'luxon';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { destroyMeeting, updateMeeting } from '../../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faClockTen } from '@fortawesome/pro-regular-svg-icons';
import {
	removeBotFromCalendarEvent,
	scheduleBotForCalendarEvent,
} from '../../../api/calendar';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { integrations } from '../../../Atoms/integrations';
import {
	upcomingMeetings,
	updateMeetingLibraryToggleAtom,
} from '../../../Atoms/recallAI';
import { BotStatus } from '../../../interfaces/recallai';
import { currentUser } from '../../../Atoms/userAtoms';
const useStyles = createStyles((theme) => ({
	container: {
		border: `1px solid ${theme.colors['secondary-text'][0]}`,
		borderRadius: theme.spacing.md,
		padding: theme.spacing.xs,
	},
	top: {
		borderBottom: `1px solid #DADCE0`,
	},
}));

interface Props {
	meeting: IMeeting;
}

export default function UpcomingMeetingItem({ meeting }: Props) {
	const { classes } = useStyles();
	const [checked, setChecked] = useState(true);
	const [popoverOpened, setPopoverOpened] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const user = useRecoilValue(currentUser);
	const { botMetadata, name, id, organizationID, recapOnly, owningUserID } =
		meeting;
	const initialValue =
		botMetadata?.status === BotStatus.BotDeleted
			? 'none'
			: recapOnly
			? 'micro'
			: 'full';
	const isOwner = user?.id === owningUserID;

	const [value, setValue] = useState<string>(initialValue);
	const [upcomingMeetingsState, setUpcomingMeetings] =
		useRecoilState(upcomingMeetings);
	const setUpdateMeetingLibraryToggle = useSetRecoilState(
		updateMeetingLibraryToggleAtom
	);

	useEffect(() => {
		const value =
			botMetadata.status === BotStatus.BotDeleted
				? 'none'
				: recapOnly
				? 'micro'
				: 'full';
		setValue(value);
	}, [meeting]);

	const handleMeetingChange = async (value: string) => {
		setValue(value);
		if (value === 'full' || value === 'micro') {
			// if full, update recapOnly to false
			// if micro, update recapOnly to true
			const recapOnly = value === 'micro';
			const updatedMeetingRes = await updateMeeting(
				{
					recapOnly,
				},
				id,
				organizationID
			);
			const updatedMeeting = updatedMeetingRes?.data?.data;
			showSuccessNotification({
				message: recapOnly
					? 'Meeting was successfully updated to recap only.'
					: 'Meeting was successfully updated to a full recording.',
			});
			// for both of these, if there is no bot, make a scheduled bot.
			if (botMetadata.status === BotStatus.BotDeleted) {
				// make a scheduled bot in recall.
				// make a bot db in our row.
				// make sure to link the bot to the correct meeting and calendar event.
				const res = await scheduleBotForCalendarEvent(
					botMetadata?.recallEventID,
					id,
					organizationID
				);
				const newBotMetadata = res?.data?.data;
				if (botMetadata) {
					updatedMeeting.botMetadata = newBotMetadata;
				}
			}
			setUpcomingMeetings((prev) =>
				prev.map((m) =>
					m.id === id
						? {
								...m,
								recapOnly,
								botMetadata: updatedMeeting.botMetadata || m.botMetadata,
						  }
						: m
				)
			);
			setUpdateMeetingLibraryToggle((prev) => !prev);
		} else if (value === 'none') {
			// if none, delete the bot if it exists.
			// need to delete recall bot, bot db row. keep calendar event in case we need
			// to re schedule a bot for this calendar event.
			const botRes = await removeBotFromCalendarEvent(
				botMetadata.recallEventID,
				id,
				organizationID,
				botMetadata.eventID
			);
			if (botRes.status === 200) {
				showSuccessNotification({
					message: 'Bot was successfully removed from this meeting.',
				});
				const botMetadata = botRes?.data?.data?.botMetadata;
				setUpcomingMeetings((prev) =>
					prev.map((m) =>
						m.id === id
							? { ...m, botMetadata: botMetadata || m.botMetadata }
							: m
					)
				);
				setTimeout(() => {
					setUpdateMeetingLibraryToggle((prev) => !prev);
				}, 1);
			}
		}
	};

	return (
		<Paper
			w={'100%'}
			shadow='sm'
			radius={'lg'}
			sx={{
				border: '1px solid #DADCE0',
			}}
		>
			<Stack h={'100%'} w={'100%'} py={'xs'}>
				<Group
					className={classes.top}
					w={'100%'}
					noWrap
					position='apart'
					pb={'xs'}
					px={'sm'}
					spacing={'xs'}
				>
					<Group
						spacing={5}
						noWrap
						align='center'
						style={{
							flex: 1,
						}}
					>
						<FontAwesomeIcon icon={faClockTen} />
						<Text fz={12} mt={2}>
							{DateTime.fromISO(
								meeting?.startAt || botMetadata?.createdAt
							).toFormat('h:mma')}
						</Text>
					</Group>
					<Group
						spacing={5}
						noWrap
						align='center'
						position='right'
						style={{
							flex: 1,
						}}
					>
						<ActionIcon
							variant={'transparent'}
							onClick={() => window.open(botMetadata?.meetingLink, '_blank')}
							size={20}
						>
							<PlatformIcon
								platform={meeting.platform}
								marginTop={2}
								size={16}
							/>
						</ActionIcon>
						<Text
							fz={12}
							mt={2}
							color='secondary-blue'
							component='a'
							target='_blank'
							href={botMetadata?.meetingLink}
						>
							Join Meeting
						</Text>
					</Group>
				</Group>
				<Group w={'100%'} noWrap position='apart' px={'sm'}>
					<Text
						color='primary'
						fz={12}
						fw={700}
						style={{
							flex: 1,
						}}
						lineClamp={2}
					>
						{name}
					</Text>
					{isOwner ? (
						<Select
							withinPortal
							rightSection={null}
							value={value}
							onChange={(val) =>
								val !== value ? handleMeetingChange(val) : null
							}
							data={[
								{ value: 'full', label: 'Record' },
								{ value: 'micro', label: 'Recap' },
								{ value: 'none', label: "Don't Join" },
							]}
							// miw={100}
							radius={'lg'}
							size='xs'
							style={{
								flex: 1,
							}}
						/>
					) : null}
				</Group>
			</Stack>
		</Paper>
	);
}
