import { atom } from 'recoil';

// Define types
export type CreateSubscriptionInput = {
	organizationId: string;
	planId: string;
	numberOfSeats: number;
	skipTrial?: boolean;
};

export type StripeSubscription = {
	id: string;
	createdAt: number;
	updatedAt: number;
	organizationID: string;
	stripeSubscriptionID: string;
	status:
		| 'trialing'
		| 'active'
		| 'canceled'
		| 'incomplete'
		| 'incomplete_expired'
		| 'past_due'
		| 'unpaid';
	currentPeriodStart: number;
	currentPeriodEnd: number;
	seatsPurchased: number;
	seatsAssigned: number;
	trialStart: number;
	trialEnd: number;
	isTrialing: boolean;
	receipts: StripeReceipt[];
	renewal: {
		renewalDate: number;
		amount: number;
		currency: string;
	};
	hasPaymentMethod: boolean;
	isLoading?: boolean;
	stripeCustomerID: string | null;
	type: 'stripe' | 'manual';
};

export type AssignSeatInput = {
	subscriptionId: string;
	userId: string;
};

export type ActivateSeatInput = {
	subscriptionId: string;
	userId: string;
};

export type DeactivateSeatInput = {
	subscriptionId: string;
	userId: string;
};

export type CheckSeatStatusInput = {
	subscriptionId: string;
	userId: string;
};

export type UpdateTrialInput = {
	trialEndDate: number | 'now';
	subscriptionId: string;
};

export type StripeReceipt = {
	id: string;
	amount: number;
	created: number;
	currency: string;
	receiptUrl: string;
	status: string;
};

export type GetAllSeatsInput = {
	subscriptionId: string;
};

// Define default StripeSubscription value
export const defaultStripeSubscription: StripeSubscription = {
	id: '',
	createdAt: 0,
	updatedAt: 0,
	organizationID: '',
	stripeSubscriptionID: '',
	status: 'incomplete',
	currentPeriodStart: 0,
	currentPeriodEnd: 0,
	seatsPurchased: 0,
	seatsAssigned: 0,
	trialStart: 0,
	trialEnd: 0,
	isTrialing: false,
	receipts: [],
	renewal: {
		renewalDate: 0,
		amount: 0,
		currency: 'usd',
	},
	hasPaymentMethod: false,
	isLoading: false,
	type: 'stripe',
	stripeCustomerID: null,
};

// Define Recoil atom
export const stripeSubscriptionAtom = atom<StripeSubscription>({
	key: 'stripeSubscriptionAtom',
	default: defaultStripeSubscription,
});
