import React, { useState } from 'react';
import { Modal, Button, TextInput, Group, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentUser, userOrganizationMembers } from '../../Atoms/userAtoms';
import { roles } from '../constants';
import { UsersMultiSelect } from '../UsersMultiSelect';
import { logger } from '../../helpers/logger';
import { inviteUserToOrganization, registerUser } from '../../api/api';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { stripeSubscriptionAtom } from '../../Atoms/billing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import ModalTitle from '../Titles/ModalTitle';
import { assignSeat, updateSeatsNumber } from '../../api/billing';
import { UserRoles } from '../../interfaces/user';
import { AxiosError } from 'axios';

interface Props {
	orgID: string;
}

interface FormValues {
	firstName: string;
	lastName: string;
	email: string;
	roles: string[];
}

export default function CreateQuickAddUserModal({ orgID }: Props) {
	const user = useRecoilValue(currentUser);
	const [users, setUsers] = useRecoilState(userOrganizationMembers);
	const [subscription, setSubscription] = useRecoilState(
		stripeSubscriptionAtom
	);
	const {
		id: subscriptionId,
		isTrialing,
		seatsAssigned,
		seatsPurchased,
	} = subscription;

	const [opened, setOpened] = useState(false);
	const [loading, setLoading] = useState(false);

	const allowedToAddUser = user.roles.some((role: string) =>
		['superadmin', 'admin', 'support', 'invite-to-organization'].includes(role)
	);

	const form = useForm<FormValues>({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			roles: [],
		},
		validate: {
			firstName: (value) => (value.length ? null : 'Invalid first name'),
			lastName: (value) => (value.length ? null : 'Invalid last name'),
			email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
			roles: (value) => (value.length ? null : 'A role is required.'),
		},
	});

	async function inviteUser(data: FormValues) {
		try {
			const createMeetingRole = data.roles.includes(UserRoles.CreateMeeting);
			// do not allow if seats are full.
			if (seatsAssigned >= seatsPurchased && !isTrialing && createMeetingRole) {
				showFailureNotification({
					message:
						'Sorry, it looks like you are out of seats… to invite this user please increase your available seats.',
				});
				return;
			}

			if (
				isTrialing &&
				seatsAssigned >= seatsPurchased &&
				!subscription.renewal?.renewalDate &&
				createMeetingRole
			) {
				showFailureNotification({
					message:
						'Sorry, it looks like you are out of seats for your free trial. Please upgrade your subscription to invite this user.',
				});
				return;
			}
			setLoading(true);

			const rolesWithoutCreateMeeting = data.roles.filter(
				(role) => role !== UserRoles.CreateMeeting
			);

			try {
				// Register the user
				const res = await registerUser(data);
				logger('info', 'User was successfully registered', res);
			} catch (err) {
				logger('error', 'Error registering user', err);
				console.error('err', err);
			}

			// Invite the user to the organization
			logger('info', 'Inviting user to organization', data);
			const response = await inviteUserToOrganization(
				{
					...data,
					roles: subscriptionId ? rolesWithoutCreateMeeting : data.roles,
				},
				orgID
			);

			if (isTrialing && seatsAssigned <= seatsPurchased && createMeetingRole) {
				// increment seat number before assigning seat
				const updatedSeatNumber = await updateSeatsNumber({
					subscriptionId,
					seats: seatsPurchased + 1,
				});

				await new Promise((resolve) => {
					const TIMEOUT_DURATION = 1000;
					setTimeout(resolve, TIMEOUT_DURATION);
				});
			}
			const newUser = response.data.data.user;

			if (createMeetingRole) {
				const seat = await assignSeat({
					subscriptionId,
					userId: response.data.data.user.id,
				});
				if (seat?.status === 'active')
					newUser.roles = [...newUser.roles, UserRoles.CreateMeeting];
			}

			setUsers((prevUsers) => [...prevUsers, newUser]);

			showSuccessNotification({
				message: 'User was successfully invited! Great work!',
			});
		} catch (err: AxiosError | any) {
			logger('error', 'Error inviting user to organization', err);
			console.error('err', err);
			showFailureNotification({
				message: `Sorry, failed to invite user. Error: ${err?.response?.data}`,
			});
		} finally {
			setLoading(false);
			setOpened(false);
			form.reset();
		}
	}

	return (
		<>
			<Modal
				opened={opened}
				centered={true}
				onClose={() => setOpened(false)}
				title={<ModalTitle text='Invite User to Organization' />}
			>
				<form onSubmit={form.onSubmit((values) => inviteUser(values))}>
					<TextInput
						mb='sm'
						label={'First Name'}
						{...form.getInputProps('firstName')}
					/>
					<TextInput
						mb='sm'
						label={'Last Name'}
						{...form.getInputProps('lastName')}
					/>
					<TextInput mb='sm' label={'Email'} {...form.getInputProps('email')} />
					<UsersMultiSelect
						errorText='A role is required.'
						data={roles}
						labelText={'Roles'}
						placeholder='Select Roles'
						name={'roles'}
						form={form}
					/>
					<Group mt={'md'} position={'right'}>
						<Button radius={'md'} type='submit' loading={loading}>
							Invite
						</Button>
					</Group>
				</form>
			</Modal>

			{allowedToAddUser ? (
				<Button
					radius={'md'}
					leftIcon={<FontAwesomeIcon icon={faPlus} />}
					onClick={() => setOpened(true)}
				>
					Add User
				</Button>
			) : null}
		</>
	);
}
