import { useEffect, useState } from 'react';
import {
	Accordion,
	ActionIcon,
	Button,
	Checkbox,
	Chip,
	CopyButton,
	createStyles,
	Group,
	Menu,
	Modal,
	Paper,
	rem,
	ScrollArea,
	Space,
	Stack,
	Text,
	Tooltip,
} from '@mantine/core';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentMeeting as MeetingAtom,
	currentMeetingActions as ActionAtom,
	currentMeetingChapters,
	currentMeetingHighlights as HighlightAtom,
	transcriptID,
	chatAtom,
} from '../../../Atoms/meetingAtom';
import {
	currentUser,
	editingHighlightAtom,
	editingHighlightID as editingHighlightIDAtom,
	editingHighlightInitialValuesAtom,
	IAttachment,
	User,
	userOrganizationMembers,
} from '../../../Atoms/userAtoms';
import {
	convertMeetingToFullAsset,
	getAttachmentsForMeeting,
	getChaptersByMeetingID,
	getHighlights,
	getMeeting,
	getTranscriptionForMeeting,
	getUserSettings,
	getUsersByOrg,
	updateMeeting,
	deleteMeetingShareableLink,
	destroyMeeting,
} from '../../../api/api';
import { CreateHighlightCard } from '../../../components/CreateHighlightCard';
import CustomChip from '../../../components/aside/CustomChip';
import {
	filterHighlightsByChipGroup,
	initialHighlightChipGroupValue,
	momentTypeChipSet,
	sortHighlightsByTimeStartMS,
} from '../../../helpers/highlights';
import { HighlightCard } from '../../../components/aside/HighlightCard';
import { DateTime } from 'luxon';
import { Utterance } from '../../../helpers/data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCircleChevronLeft,
	faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FilePreviewModal } from '../../../components/create_reelay_modal/FilePreviewModal';
import { IHighlight } from '../../../interfaces/highlight';
import { IAction } from '../../../interfaces/action';
import { organizationAtom } from '../../../Atoms/organizationAtom';
import ReelayLogoPageLoader from '../../../components/loaders/ReelayLogoPageLoader';
import { logger } from '../../../helpers/logger';
import { useDisclosure } from '@mantine/hooks';
import { sortChaptersByTime } from '../../../helpers/chapters';
import TranscriptPanel from '../../../components/aside/TabPanels/Transcript';
import { useMediaQuery } from 'react-responsive';
import VideoPlayerAndMeetingDetailsColumn from './components/VideoPlayerAndMeetingDetailsColumn';
import ReelayMobilePlayer from '../../../components/reelay_mobile_player';
import RegenerateMeetingDetailsButton from './components/RegenerateMeetingDetailsButton';
import RegenerateMeetingDetailsModal from './components/RegenerateMeetingDetailsModal';
import TimeInAuditTimer from './components/TimeInAuditTimer';
import { getRecallAIBotDetails } from '../../../api/recall';
import { getRecallBotEvent } from '../../../api/recallBotEvent';
import { recallAIBotDetails, recallAIBotEvent } from '../../../Atoms/recallAI';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { InvitedUser } from '../../../interfaces/user';
import _ from 'lodash';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { useFlags } from 'flagsmith/react';
import { destructureSettingsResponse } from '../../../helpers/settings/settings';
import { OrganizationSettings } from '../../../Atoms/settings';
import { getOrganizationSettings } from '../../../api/settings';
import ChatPanel from '../../../components/Chat';
import { getChatByMeetingID } from '../../../api/chat';
import {
	faBook,
	faEllipsisVertical,
	faRightLeft,
} from '@fortawesome/pro-regular-svg-icons';
import TransferMeetingOwnerModal from './components/TransferMeetingOwnerModal';
import { generateMeetingTranscript } from '../../../api/support';
import { reelayAIModels } from '../../../components/constants';
import { modals } from '@mantine/modals';

const useStyles = createStyles((theme) => ({
	cardText: {
		fontSize: 12,
		color: theme.colors.primary[5],
	},
	card: {},
	title: {
		color: theme.colors?.['primary-text'],
		fontWeight: 600,
		fontSize: 12,
	},
	sectionHeader: {
		color: theme.colors?.['primary-text'],
		fontWeight: 600,
		fontSize: 16,
	},
	dateText: {
		color: theme.colors?.['primary-text'],
		fontSize: 12,
	},
	submitButton: {
		backgroundColor: theme.colors?.primary[8],
	},
	'&:hover': {
		backgroundColor: theme.colors?.primary[8],
	},
}));

const CHECKLIST_LENGTH = 5;

export const AuditPage = () => {
	const navigate = useNavigate();
	const { meetingID } = useParams();
	const { classes, theme } = useStyles();
	const flags = useFlags(['audit_options']);
	const auditOptionsEnabled = flags?.audit_options?.enabled;
	const auditOptionsParsed = JSON.parse(flags?.audit_options?.value as any);
	const { transcript_readable, transcript_viewable } = !auditOptionsEnabled
		? {
				transcript_readable: false,
				transcript_viewable: true,
		  }
		: auditOptionsParsed;
	const isLessThanLaptop = useMediaQuery({
		maxWidth: `${theme.breakpoints.md}`,
	});

	const isMobile = useMediaQuery({
		maxWidth: `${theme.breakpoints.xs}`,
	});
	const [highlightChipGroupVal, setHighlightChipGroupVal] = useState<string[]>(
		initialHighlightChipGroupValue
	);
	const [currentMeeting, setCurrentMeeting] = useRecoilState(MeetingAtom);
	const {
		id,
		name,
		summary,
		videoMetadata,
		botMetadata,
		createdAt,
		publishedAt,
		organizationID,
		owningUserID,
		longSummary,
		status,
	} = currentMeeting;
	const [currentHighlights, setCurrentHighlights] =
		useRecoilState(HighlightAtom);
	const [botDetails, setBotDetails] = useRecoilState(recallAIBotDetails);
	const [botEvent, setBotEvent] = useRecoilState(recallAIBotEvent);
	const setChapters = useSetRecoilState(currentMeetingChapters);
	const setTranscriptID = useSetRecoilState(transcriptID);

	const [currentAttachments, setCurrentAttachments] = useState<IAttachment[]>(
		[]
	);
	const [filePreviews, setFilePreviews] = useState<object>({});
	const [currentFilePreview, setCurrentFilePreview] = useState<number>(0);
	const [viewingPreview, setViewingPreview] = useState<boolean>(false);
	const [auditChecklistValue, setAuditChecklistValue] = useState<string[]>([]);
	const [loading, setLoading] = useState(true);
	const currentOrganization = useRecoilValue(organizationAtom);
	const [utterances, setUtterances] = useState<Utterance[]>([]);
	const [editingHighlight, setEditingHighlight] =
		useRecoilState(editingHighlightAtom);
	const setEditingHighlightInitialValues = useSetRecoilState(
		editingHighlightInitialValuesAtom
	);
	const users = useRecoilValue(userOrganizationMembers);
	const [chat, setChat] = useRecoilState(chatAtom);
	const setEditingHighlightID = useSetRecoilState(editingHighlightIDAtom);
	const [currentUsers, setCurrentUsers] = useState([]);
	const [opened, { open, close }] = useDisclosure(false);
	const [model, setModel] = useState(reelayAIModels['openai-3.5']);
	const [type, setType] = useState('');
	const [auditButtonLoading, setAuditButtonLoading] = useState(false);
	const [fullAssetButtonLoading, setFullAssetButtonLoading] = useState(false);
	const user = useRecoilValue(currentUser);

	const [userSettings, setUserSettings] = useState<{
		job_title?: string;
		bio?: string;
		meeting_minutes?: boolean;
		address?: object;
	}>({
		job_title: '',
		bio: '',
		meeting_minutes: false,
		address: {},
	});
	const [orgSettings, setOrgSettings] = useState<OrganizationSettings>({});
	const [openTransferOwnerModal, setOpenTransferOwnerModal] = useState(false);
	const [refetchData, setRefetchData] = useState(false);
	const [orgUsers, setOrgUsers] = useState<User[]>([]);
	const minutesOn = userSettings.meeting_minutes || orgSettings.meeting_minutes;

	const meetingHost = currentUsers.find(
		(user: InvitedUser) => user?.id === owningUserID
	);

	const handleMenuSelect = (model: string, type: string) => {
		setModel(model);
		setType(type);
		open();
	};

	const fetchMembersData = async (organizationID: string) => {
		let users = userData;

		const res = await getUsersByOrg(organizationID);
		users = (res || []).map((user: User) => ({
			...user,
			label: user.email,
			value: user.email,
			key: user.id,
		}));
		setOrgUsers(users);
	};

	useEffect(() => {
		// We already have meetings [] so we can grab it there.
		// if we are unable to find it then we fetch the info.
		const getMeetingDetails = async () => {
			try {
				setLoading(true);
				const meeting = await handleGetMeeting(meetingID);
				Promise.all([
					handleGetHighlights(meeting.id),
					handleGetAttachments(meeting.id, meeting.organizationID),
					handleGetChat(meeting.id, meeting.organizationID),
					handleGetMeetingChapters(meetingID),
					handleGetAudioTranscript(meetingID),
					handleGetBotDetails(meeting?.botMetadata?.recallBotID),
					handleGetBotEvent(
						meeting?.botMetadata?.recallEventID,
						meeting.organizationID
					),
					handleGetSettings(meeting?.owningUserID, meeting?.organizationID),
					setCurrentMeeting(meeting),
					fetchMembersData(meeting.organizationID),
				])
					.then((res) => {
						logger('info', 'fetching meeting', res);
					})
					.catch((err) => {
						logger('error', 'fetching meeting', err);
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (err) {
				logger('error', 'fetching meeting details', err);
				showFailureNotification({
					message:
						'There was an error fetching the meeting. Please try refreshing the page or navigating back and forth.',
				});
			} finally {
				setLoading(false);
			}
		};
		getMeetingDetails();
		return () => {
			// setCurrentMeeting();
			setCurrentHighlights([]);
			setCurrentAttachments([]);
			setBotDetails({});
			setBotEvent({});
		};
	}, [MeetingAtom, HighlightAtom, ActionAtom, refetchData]);

	useEffect(() => {
		for (const attachment of currentAttachments) createFilePreview(attachment);
	}, []);

	const userData = users
		.map((user: User) => ({
			...user,
			label: user.email,
			value: user.email,
			key: user.id,
		}))
		.filter((item: any, index, array) => {
			return (
				user.id !== item.id &&
				array.findIndex((i: any) => i.id === item.id) === index
			);
		});

	const createFilePreview = async (attachment: IAttachment) => {
		try {
			setFilePreviews((filePreviews: object) => ({
				...filePreviews,
				[attachment.id]: {
					uri: attachment.attachmentURL,
					name: attachment.fileName,
				},
			}));
		} catch (err) {
			logger('error', 'creating file preview', err);
		}
	};

	const previewAttachment = (idx: number) => {
		try {
			setCurrentFilePreview(idx);
			setViewingPreview(true);
		} catch (err) {
			logger('error', 'previewing attachment', err);
		}
	};

	const handleGetMeeting = async (meetingID: string | undefined) => {
		try {
			logger('info', 'handleGetMeeting', { meetingID: meetingID });
			const currentMeetingVar = await getMeeting(
				meetingID,
				currentOrganization.id
			);
			logger('info', 'handleGetMeeting', currentMeetingVar);
			setCurrentMeeting(currentMeetingVar.data.data);
			return currentMeetingVar.data.data;
		} catch (err) {
			logger('error', 'fetching meeting', err);
		}
	};

	const handleGetAudioTranscript = async (meetingID: string) => {
		try {
			logger('info', 'handleGetAudioTranscript', { meetingID: meetingID });
			const transcription = await getTranscriptionForMeeting(
				meetingID,
				organizationID
			);
			logger('info', 'handleGetAudioTranscript retrieved', transcription);
			const { utterances, id } = transcription.data.data.transcription;
			setTranscriptID(id);
			setUtterances(
				utterances.map((utterance: Utterance) => ({
					...utterance,
					speaker:
						utterance.speaker === null ? 'Unknown Speaker' : utterance.speaker,
				}))
			);
		} catch (err) {
			logger('error', 'fetching transcription', err);
		}
	};

	const handleGetBotDetails = async (recallBotID: string) => {
		try {
			if (!recallBotID) return;
			logger('info', 'handleGetBotDetails', { recallBotID });
			const botDetailsResponse = await getRecallAIBotDetails(recallBotID);
			const host = botDetailsResponse?.meeting_participants.find(
				(user) => user?.is_host
			);
			setBotDetails({ ...botDetailsResponse, host });
			logger('info', 'handleGetBotDetails', botDetails);
		} catch (err) {
			logger('error', 'handleGetBotDetails', err);
		}
	};

	const handleGetBotEvent = async (
		recallEventID: string,
		organizationID: string
	) => {
		try {
			if (!recallEventID) return;
			const botEventResponse = await getRecallBotEvent(
				recallEventID,
				organizationID
			);
			setBotEvent(botEventResponse);
			logger('info', 'handleGetBotEvent', botEvent);
		} catch (err) {
			logger('error', 'handleGetBotEvent', err);
		}
	};

	const handleGetSettings = async (userID: string, organizationID: string) => {
		try {
			if (!userID) return;
			logger('info', 'handleGetSettings', { userID, organizationID });
			const [userSettings, orgSettings] = await Promise.all([
				getUserSettings(userID),
				getOrganizationSettings(organizationID),
			]);
			const userData = destructureSettingsResponse(userSettings);
			const orgData = destructureSettingsResponse(orgSettings);
			setUserSettings(userData);
			setOrgSettings(orgData);
			logger('info', 'handleGetSettings', { userData, orgData });
		} catch (err) {
			logger('error', 'handleGetSettings', err);
		}
	};

	const handleGetHighlights = async (meetingID: string | undefined) => {
		try {
			logger('info', 'handleGetHighlights', { meetingID: meetingID });
			const meetingHighlights = await getHighlights(meetingID, organizationID);
			logger('info', 'handleGetHighlights', meetingHighlights);
			setCurrentHighlights(meetingHighlights);
		} catch (err) {
			logger('error', 'fetching highlights', err);
		}
	};

	const handleGetMeetingChapters = async (meetingID: string) => {
		try {
			const chapters = await getChaptersByMeetingID(meetingID, organizationID);
			const chaptersContent = _.get(chapters, 'data.data.chapters', []);
			setChapters(sortChaptersByTime(chaptersContent));
		} catch (err) {
			logger('error', 'fetching chapters', err);
		}
	};

	const handleGetAttachments = async (
		meetingID: string,
		organizationID: string
	) => {
		try {
			const attachments = await getAttachmentsForMeeting(
				meetingID,
				organizationID
			);
			logger('info', 'handleGetAttachments', attachments);
			setCurrentAttachments(attachments.data.data);
		} catch (err) {
			logger('error', 'fetching attachments', err);
		}
	};

	const handleGetChat = async (meetingID: string, organizationID: string) => {
		try {
			const res = await getChatByMeetingID(meetingID, organizationID);
			setChat(res?.data?.data || []);
		} catch (err) {
			logger('error', 'fetching attachments', err);
		}
	};

	const publishMeeting = async () => {
		try {
			setAuditButtonLoading(true);
			if (!summary) {
				showFailureNotification({
					message: 'Please add a summary below.',
				});
				return;
			}
			const updatedMeetingResponse = await updateMeeting(
				{
					status: publishedAt ? 'planned' : 'published',
					publishedAt: publishedAt ? publishedAt : undefined,
					auditedByUserID: user.id,
				},
				id,
				organizationID
			);
			setCurrentMeeting(updatedMeetingResponse.data.data);
			showSuccessNotification({
				message: 'Meeting was successfully published!',
			});
			navigate('/admin/meetings');
		} catch (err) {
			logger('error', 'publishing meeting', err);
			showFailureNotification({
				message: 'Failed to publish meeting. Please try again',
			});
		} finally {
			setAuditButtonLoading(false);
		}
	};

	const handleEditHighlight = (highlight: IHighlight | IAction) => {
		try {
			const { content, type, visibility, timeStartMS, id, isTopQuestion } =
				highlight;
			// sets the overlay
			setEditingHighlight(true);
			// prefill form value and edit card
			setEditingHighlightInitialValues({
				content,
				type,
				visibility,
				timeStartMS,
				isTopQuestion,
			});
			// set id to get highlight info inside card
			setEditingHighlightID(id);
		} catch (err) {
			logger('error', 'editing highlight', err);
		}
	};

	const moveMeetingToAudit = async () => {
		try {
			setAuditButtonLoading(true);
			const updatedMeetingResponse = await updateMeeting(
				{
					status: 'audit',
				},
				id,
				organizationID
			);
			showSuccessNotification({
				message: 'Meeting was successfully moved to audit!',
			});
			navigate('/admin/meetings');
		} catch (err) {
			logger('error', 'moving meeting to audit', err);
			showFailureNotification({
				message: 'Failed to move meeting to audit. Please try again.',
			});
		} finally {
			setAuditButtonLoading(false);
		}
	};

	const generateTranscript = async () => {
		try {
			const res = await generateMeetingTranscript(id);
			if (res?.status === 'success') {
				showSuccessNotification({
					message: 'Transcript has been generated!',
				});
			} else {
				showFailureNotification({
					message:
						'Failed to generate transcript. Please try again. If it does not work, notify the dev team.',
				});
			}

			setRefetchData(!refetchData);
		} catch (err) {
			logger('error', 'generating transcript', err);
			showFailureNotification({
				message: 'Failed to generate transcript. Please try again.',
			});
		} finally {
			setAuditButtonLoading(false);
		}
	};

	const AuditButton = () => {
		return (
			<Button
				onClick={moveMeetingToAudit}
				className={classes.submitButton}
				loading={auditButtonLoading}
				radius={'md'}
			>
				Move to Audit
			</Button>
		);
	};

	const handleConvertToFullAsset = async () => {
		setFullAssetButtonLoading(true);
		const res = await convertMeetingToFullAsset(id);
		const meeting = res.data.data;
		setCurrentMeeting({
			...currentMeeting,
			videoMetadata: meeting.videoMetadata,
			recapOnly: false,
		});

		setFullAssetButtonLoading(false);

		showSuccessNotification({
			message:
				'This meeting has now begun converting to a full asset. This may take a few minutes. You will be notified when it is complete. Do not click this button again!',
		});
	};

	const handleDeleteMeeting = () => {
		modals.openConfirmModal({
			title: 'Delete Meeting',
			centered: true,
			children: (
				<Text size='sm'>
					Are you sure you want to delete this meeting? This action cannot be
					undone.
				</Text>
			),
			labels: { confirm: 'Delete', cancel: 'Cancel' },
			confirmProps: { color: 'red' },
			onConfirm: async () => {
				try {
					await destroyMeeting(id, organizationID);
					showSuccessNotification({
						message: 'Meeting was successfully deleted!',
					});
					navigate('/admin/meetings');
				} catch (err) {
					logger('error', 'deleting meeting', err);
					showFailureNotification({
						message: 'Failed to delete meeting. Please try again.',
					});
				}
			},
		});
	};

	return (
		<>
			{loading ? (
				<ReelayLogoPageLoader />
			) : (
				<>
					<TransferMeetingOwnerModal
						opened={openTransferOwnerModal}
						setOpened={setOpenTransferOwnerModal}
						orgUsers={orgUsers}
						setRefetchData={setRefetchData}
					/>
					<RegenerateMeetingDetailsModal
						opened={opened}
						close={close}
						title={`Editing Meeting`}
						type={type}
						model={model}
						currentSummary={type === 'summary' ? summary : longSummary}
					/>
					{isLessThanLaptop ? (
						<Stack h={'100%'} w={'100%'} align={'center'} pb={'md'}>
							<Stack align={'center'} style={{ overflowY: 'auto', flex: 2 }}>
								{' '}
								{/*Preview Modal*/}
								{viewingPreview && (
									<FilePreviewModal
										files={currentAttachments}
										viewingPreview={viewingPreview}
										setViewingPreview={setViewingPreview}
										currentFilePreview={currentFilePreview}
										filePreviews={filePreviews}
									/>
								)}
								<Stack
									spacing={0}
									w={'100%'}
									style={{
										flex: 2,
									}}
								>
									<Group position={'apart'} noWrap pl={isMobile ? 'md' : 0}>
										<ActionIcon
											m={10}
											ml={'lg'}
											variant='transparent'
											component={Link}
											to={'/admin/meetings'}
										>
											<FontAwesomeIcon icon={faCircleChevronLeft} />
											<Text ml='xs'>Back</Text>
										</ActionIcon>
										{}
										{videoMetadata?.source !== 'mux' ? (
											<Button
												variant='outline'
												onClick={handleConvertToFullAsset}
												loading={fullAssetButtonLoading}
												disabled={videoMetadata?.status === 'preparing'}
											>
												Convert to Full Asset
											</Button>
										) : null}
									</Group>
									<Group grow>
										<Paper shadow='lg' radius='lg' p='sm'>
											<Group position={'apart'}>
												<Text className={classes.dateText}>
													<Text span className={classes.title}>
														{'Name: '}
													</Text>
													{name}
												</Text>
												<Text className={classes.dateText}>
													<Text span className={classes.title}>
														Created At:{' '}
													</Text>
													{DateTime.fromISO(createdAt).toLocaleString(
														DateTime.DATE_MED
													)}
												</Text>
												{botMetadata?.joinAt ? (
													<Text className={classes.dateText}>
														<Text span className={classes.title}>
															Meeting Date:{' '}
														</Text>
														{DateTime.fromISO(botMetadata?.joinAt).toFormat(
															'LLL d, h:mm a'
														)}
													</Text>
												) : (
													<Text className={classes.dateText}>
														<Text span className={classes.title}>
															Created At:{' '}
														</Text>
														{DateTime.fromISO(createdAt).toLocaleString(
															DateTime.DATE_MED
														)}
													</Text>
												)}
												<Text className={classes.dateText}>
													<Text span className={classes.title}>
														Distribution preference:
													</Text>{' '}
													{orgSettings.distribution_preference}
												</Text>
												<Text className={classes.dateText}>
													<Text span className={classes.title}>
														Reelay Owner:
													</Text>{' '}
													{`${meetingHost?.firstName} ${meetingHost?.lastName}`}
												</Text>
												<Text className={classes.dateText}>
													<Text span className={classes.title}>
														Meeting Minutes:{' '}
													</Text>
													{minutesOn ? (
														<span style={{ color: 'green' }}>✓</span>
													) : (
														<span style={{ color: 'red' }}>X</span>
													)}
												</Text>
											</Group>
										</Paper>
									</Group>
									{videoMetadata ? (
										<ReelayMobilePlayer
											playbackID={videoMetadata?.playbackID}
											meeting={currentMeeting}
										/>
									) : (
										<Text>Woah - no video info!</Text>
									)}
								</Stack>
								<RegenerateMeetingDetailsButton
									handleMenuSelect={handleMenuSelect}
								/>
								<Accordion
									variant='separated'
									radius='lg'
									w={'100%'}
									styles={{
										item: {
											backgroundColor: 'white',
										},
									}}
								>
									<Accordion.Item value='details'>
										<Accordion.Control>Details</Accordion.Control>
										<Accordion.Panel>
											<VideoPlayerAndMeetingDetailsColumn
												showVideoPlayer={false}
												showDetails={true}
												previewAttachment={previewAttachment}
												createFilePreview={createFilePreview}
												handleMenuSelect={handleMenuSelect}
												currentUsers={currentUsers}
												setCurrentUsers={setCurrentUsers}
												userSettings={userSettings}
												orgSettings={orgSettings}
												orgUsers={orgUsers}
											/>
										</Accordion.Panel>
									</Accordion.Item>

									<Accordion.Item value='highlights'>
										<Accordion.Control>Moments</Accordion.Control>
										<Accordion.Panel>
											{' '}
											<Stack align={'stretch'} h={'100%'} w={'100%'}>
												<Group grow w={'100%'}>
													<Modal
														withCloseButton={false}
														opened={editingHighlight}
														onClose={() => setEditingHighlight(false)}
													>
														<CreateHighlightCard
															meeting={currentMeeting}
															cardTitle={'Edit Highlight'}
															buttonText={'Save Highlight'}
															isUpdating={true}
														/>
													</Modal>
													<CreateHighlightCard meeting={currentMeeting} />
												</Group>
												<Paper shadow='lg' radius='lg' p='sm'>
													<Text className={classes.sectionHeader}>
														Current Highlights
													</Text>
													<Chip.Group
														multiple
														value={highlightChipGroupVal}
														onChange={setHighlightChipGroupVal}
													>
														<Group w={'100%'} position={'center'}>
															<Group
																position='apart'
																noWrap
																spacing={'sm'}
																mb={16}
																mt={16}
															>
																{momentTypeChipSet.map(
																	({ value, label, color }) => (
																		<CustomChip
																			key={value}
																			color={color}
																			size={'xs'}
																			value={value}
																			label={label}
																		/>
																	)
																)}
															</Group>
														</Group>
													</Chip.Group>
													<Stack align={'center'}>
														{currentHighlights.length ? (
															<ScrollArea h={400} w={'100%'}>
																{sortHighlightsByTimeStartMS(
																	filterHighlightsByChipGroup(
																		currentHighlights,
																		highlightChipGroupVal
																	)
																).map((highlight, index) => (
																	<HighlightCard
																		key={highlight.id}
																		highlight={highlight}
																		organizationID={organizationID}
																		isAdmin={true}
																		meeting={currentMeeting}
																		index={index}
																		handleEditHighlight={handleEditHighlight}
																		isAuditPage={true}
																	/>
																))}
															</ScrollArea>
														) : (
															<Text
																align={'center'}
																mt={'8px'}
																color={'secondary-text'}
															>
																You have no highlights for this Reelay.
															</Text>
														)}
													</Stack>
												</Paper>
											</Stack>
										</Accordion.Panel>
									</Accordion.Item>

									<Accordion.Item value='transcript'>
										<Accordion.Control>Transcript</Accordion.Control>
										<Accordion.Panel>
											<Stack
												align={'center'}
												justify={'flex-start'}
												h={'100%'}
												w={'100%'}
											>
												<Group grow position={'center'} w={'100%'}>
													<Paper shadow='lg' radius='lg' p='sm' w={'100%'}>
														<ScrollArea
															h={transcript_viewable ? '600px' : 'auto'}
															scrollbarSize={10}
														>
															<Stack>
																<TranscriptPanel
																	utterances={utterances}
																	meeting={currentMeeting}
																	showTitle={false}
																	isAuditPage={true}
																	refreshTranscript={handleGetAudioTranscript}
																/>
															</Stack>
														</ScrollArea>
													</Paper>
												</Group>
											</Stack>
										</Accordion.Panel>
									</Accordion.Item>
									<Accordion.Item value='chat'>
										<Accordion.Control>Chat</Accordion.Control>
										<Accordion.Panel>
											<ChatPanel
												meeting={currentMeeting}
												isAuditPage={true}
												chatProps={chat}
											/>
										</Accordion.Panel>
									</Accordion.Item>
								</Accordion>
								{/* Timer, Save, Transcript, Audience, Audit Checklist */}
								<Stack w={'100%'} h={'auto'}>
									<Paper shadow='lg' radius='lg' p='sm'>
										<Text className={classes.sectionHeader}>
											Audit Checklist
										</Text>
										<Checkbox.Group
											label='Complete all items before publishing meeting.'
											withAsterisk
											value={auditChecklistValue}
											onChange={setAuditChecklistValue}
											size={'sm'}
										>
											<Stack mt={'md'} p={'sm'}>
												<Checkbox
													size={'sm'}
													value='1'
													label='Review Summary'
												/>
												<Checkbox
													size={'sm'}
													value='2'
													label='Review Topics Titles'
												/>
												<Checkbox
													size={'sm'}
													value='3'
													label='Review Thumbnail Image'
												/>
												<Checkbox
													size={'sm'}
													value='4'
													label='Review Actions and Highlights'
												/>
												<Checkbox
													size={'sm'}
													value='5'
													label='Review Transcript'
												/>
											</Stack>
										</Checkbox.Group>
									</Paper>
								</Stack>
							</Stack>
							<Group
								position={'right'}
								align={'center'}
								w={'100%'}
								mt={8}
								noWrap
							>
								<TimeInAuditTimer />
								<CopyButton value={id} timeout={2000}>
									{({ copied, copy }) => (
										<Tooltip
											label={copied ? 'Meeting ID copied' : 'Copy meeting ID'}
											withArrow
											position='right'
										>
											<ActionIcon
												color={copied ? 'teal' : 'gray'}
												variant='subtle'
												onClick={copy}
											>
												{copied ? (
													<IconCheck style={{ width: rem(16) }} />
												) : (
													<IconCopy style={{ width: rem(16) }} />
												)}
											</ActionIcon>
										</Tooltip>
									)}
								</CopyButton>
								<Menu shadow='md' width={200}>
									<Menu.Target>
										<ActionIcon variant='transparent'>
											<FontAwesomeIcon
												icon={faEllipsisVertical}
												style={{
													color: theme.colors['secondary-gray'][0],
												}}
											/>
										</ActionIcon>
									</Menu.Target>

									<Menu.Dropdown>
										<Menu.Item
											icon={<FontAwesomeIcon icon={faRightLeft} />}
											onClick={() => setOpenTransferOwnerModal(true)}
										>
											Transfer Ownership
										</Menu.Item>
										<Menu.Item
											icon={<FontAwesomeIcon icon={faBook} />}
											onClick={generateTranscript}
										>
											Generate Transcript
										</Menu.Item>
										<Menu.Item
											color='red'
											icon={<FontAwesomeIcon icon={faTrash} />}
											onClick={handleDeleteMeeting}
										>
											Delete Meeting
										</Menu.Item>
									</Menu.Dropdown>
								</Menu>
								<Tooltip
									label='Must complete audit checklist.'
									disabled={auditChecklistValue.length > 1}
								>
									<span data-tip='Lorem ipsum' data-tip-disable={false}>
										{status === 'audit' ? (
											<Button
												radius={'md'}
												onClick={publishMeeting}
												className={classes.submitButton}
												disabled={auditChecklistValue.length < CHECKLIST_LENGTH}
												loading={auditButtonLoading}
											>
												Publish
											</Button>
										) : (
											<AuditButton />
										)}
									</span>
								</Tooltip>
							</Group>
							{isMobile && <Space h={50} />}
						</Stack>
					) : (
						<Group
							grow
							align={'center'}
							m={10}
							h={'100%'}
							w={'100%'}
							style={{ position: 'relative', overflowY: 'auto' }}
						>
							{/*Preview Modal*/}
							{viewingPreview && (
								<FilePreviewModal
									files={currentAttachments}
									viewingPreview={viewingPreview}
									setViewingPreview={setViewingPreview}
									currentFilePreview={currentFilePreview}
									filePreviews={filePreviews}
								/>
							)}

							{/* Timer, Save, Transcript, Audience, Audit Checklist */}
							<Stack
								align={'center'}
								justify={'flex-start'}
								h={'100%'}
								w={'100%'}
							>
								<Group position={'apart'} w={'100%'}>
									<ActionIcon
										m={10}
										ml={'xl'}
										variant='transparent'
										component={Link}
										to={'/admin/meetings'}
									>
										<FontAwesomeIcon icon={faCircleChevronLeft} />
										<Text ml='xs'>Back</Text>
									</ActionIcon>
									{videoMetadata?.source !== 'mux' ? (
										<Button
											variant='outline'
											onClick={handleConvertToFullAsset}
											loading={fullAssetButtonLoading}
											disabled={videoMetadata?.status === 'preparing'}
										>
											Convert to Full Asset
										</Button>
									) : null}
								</Group>

								<Stack w={'100%'} h={'auto'}>
									<Paper shadow='lg' radius='lg' p='sm'>
										<Text className={classes.sectionHeader}>
											{' '}
											Audit Checklist
										</Text>
										<Checkbox.Group
											label='Complete all items before publishing meeting.'
											withAsterisk
											value={auditChecklistValue}
											onChange={setAuditChecklistValue}
											size={'sm'}
										>
											<Stack mt={'md'} p={'sm'}>
												<Checkbox
													size={'sm'}
													value='1'
													label='Review Summary'
												/>
												<Checkbox
													size={'sm'}
													value='2'
													label='Review Topic Titles'
												/>
												<Checkbox
													size={'sm'}
													value='3'
													label='Review Thumbnail Image'
												/>
												<Checkbox
													size={'sm'}
													value='4'
													label='Review Actions and Highlights'
												/>
												<Checkbox
													size={'sm'}
													value='5'
													label='Review Transcript'
												/>
											</Stack>
										</Checkbox.Group>
									</Paper>
								</Stack>

								<Group grow position={'center'} w={'100%'}>
									<Paper shadow='lg' radius='lg' p='sm' w={'100%'}>
										<ScrollArea
											h={transcript_viewable ? '600px' : 'auto'}
											scrollbarSize={10}
										>
											<Stack>
												<Accordion
													variant='separated'
													radius='lg'
													w={'100%'}
													defaultValue='transcript'
												>
													<Accordion.Item value='transcript'>
														<Accordion.Control>Transcript</Accordion.Control>
														<Accordion.Panel>
															<Stack
																align={'center'}
																justify={'flex-start'}
																h={'100%'}
																w={'100%'}
															>
																<Group grow position={'center'} w={'100%'}>
																	<Paper
																		shadow='lg'
																		radius='lg'
																		p='sm'
																		w={'100%'}
																	>
																		<ScrollArea
																			h={transcript_viewable ? '600px' : 'auto'}
																			scrollbarSize={10}
																		>
																			<Stack>
																				<TranscriptPanel
																					utterances={utterances}
																					meeting={currentMeeting}
																					showTitle={false}
																					isAuditPage={true}
																					refreshTranscript={
																						handleGetAudioTranscript
																					}
																				/>
																			</Stack>
																		</ScrollArea>
																	</Paper>
																</Group>
															</Stack>
														</Accordion.Panel>
													</Accordion.Item>
													<Accordion.Item value='chat'>
														<Accordion.Control>Chat</Accordion.Control>
														<Accordion.Panel>
															<ChatPanel
																meeting={currentMeeting}
																isAuditPage={true}
																chatProps={chat}
															/>
														</Accordion.Panel>
													</Accordion.Item>
												</Accordion>
											</Stack>
										</ScrollArea>
									</Paper>
								</Group>
							</Stack>

							<VideoPlayerAndMeetingDetailsColumn
								previewAttachment={previewAttachment}
								createFilePreview={createFilePreview}
								showVideoPlayer={true}
								showDetails={true}
								handleMenuSelect={handleMenuSelect}
								currentUsers={currentUsers}
								setCurrentUsers={setCurrentUsers}
								userSettings={userSettings}
								orgSettings={orgSettings}
								orgUsers={orgUsers}
							/>

							<Stack align={'stretch'} h={'100%'}>
								<Group position={'apart'} align={'center'} w={'100%'} mt={8}>
									<RegenerateMeetingDetailsButton
										handleMenuSelect={handleMenuSelect}
									/>
									<Group noWrap>
										<TimeInAuditTimer />
										<CopyButton value={id} timeout={2000}>
											{({ copied, copy }) => (
												<Tooltip
													label={
														copied ? 'Meeting ID copied!' : 'Copy meeting ID'
													}
													withArrow
													position='right'
												>
													<ActionIcon
														color={copied ? 'teal' : 'gray'}
														variant='subtle'
														onClick={copy}
													>
														{copied ? (
															<IconCheck style={{ width: rem(16) }} />
														) : (
															<IconCopy style={{ width: rem(16) }} />
														)}
													</ActionIcon>
												</Tooltip>
											)}
										</CopyButton>
										<Menu shadow='md' width={200}>
											<Menu.Target>
												<ActionIcon variant='transparent'>
													<FontAwesomeIcon
														icon={faEllipsisVertical}
														style={{
															color: theme.colors['secondary-gray'][0],
														}}
													/>
												</ActionIcon>
											</Menu.Target>

											<Menu.Dropdown>
												<Menu.Item
													icon={<FontAwesomeIcon icon={faRightLeft} />}
													onClick={() => setOpenTransferOwnerModal(true)}
												>
													Transfer Ownership
												</Menu.Item>
												<Menu.Item
													icon={<FontAwesomeIcon icon={faBook} />}
													onClick={generateTranscript}
												>
													Generate Transcript
												</Menu.Item>
												<Menu.Item
													color='red'
													icon={<FontAwesomeIcon icon={faTrash} />}
													onClick={handleDeleteMeeting}
												>
													Delete Meeting
												</Menu.Item>
											</Menu.Dropdown>
										</Menu>
										<Tooltip
											label='Must complete audit checklist.'
											disabled={auditChecklistValue.length > 1}
										>
											<span data-tip='Lorem ipsum' data-tip-disable={false}>
												{status === 'audit' ? (
													<Button
														radius={'md'}
														onClick={publishMeeting}
														className={classes.submitButton}
														disabled={
															auditChecklistValue.length < CHECKLIST_LENGTH
														}
														loading={auditButtonLoading}
													>
														Publish
													</Button>
												) : (
													<AuditButton />
												)}
											</span>
										</Tooltip>
									</Group>
								</Group>

								<Group grow w={'100%'}>
									<Modal
										withCloseButton={false}
										opened={editingHighlight}
										onClose={() => setEditingHighlight(false)}
									>
										<CreateHighlightCard
											meeting={currentMeeting}
											cardTitle={'Edit Highlight'}
											buttonText={'Save Highlight'}
											isUpdating={true}
										/>
									</Modal>
									<CreateHighlightCard meeting={currentMeeting} />
								</Group>
								<Paper shadow='lg' radius='lg' p='sm'>
									<Text className={classes.sectionHeader}>
										Current Highlights
									</Text>
									<Chip.Group
										multiple
										value={highlightChipGroupVal}
										onChange={setHighlightChipGroupVal}
									>
										<Group w={'100%'} position={'center'}>
											<Group
												position='apart'
												noWrap
												spacing={'sm'}
												mb={16}
												mt={16}
											>
												{momentTypeChipSet.map(({ value, label, color }) => (
													<CustomChip
														key={value}
														color={color}
														size={'xs'}
														value={value}
														label={label}
													/>
												))}
											</Group>
										</Group>
									</Chip.Group>
									<Stack justify={'flex-start'} align={'stretch'}>
										{currentHighlights.length ? (
											<ScrollArea h={400}>
												{sortHighlightsByTimeStartMS(
													filterHighlightsByChipGroup(
														currentHighlights,
														highlightChipGroupVal
													)
												).map((highlight, index) => (
													<HighlightCard
														key={highlight.id}
														highlight={highlight}
														organizationID={organizationID}
														isAdmin={true}
														meeting={currentMeeting}
														index={index}
														handleEditHighlight={handleEditHighlight}
														isAuditPage={true}
													/>
												))}
											</ScrollArea>
										) : (
											<Text
												align={'center'}
												mt={'8px'}
												color={'secondary-text'}
											>
												You have no highlights for this Reelay.
											</Text>
										)}
									</Stack>
								</Paper>
							</Stack>
						</Group>
					)}
				</>
			)}
		</>
	);
};
