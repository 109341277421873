import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentUser,
	currentUserReelays,
	userOrganization,
} from '../../Atoms/userAtoms';
import {
	destroyMeetingHighlights,
	getActions,
	getQuery,
	markActionItems,
	sendActionReminderEmail,
} from '../../api/api';
import SearchActionsBar from '../../components/SearchBars/SearchActionsBar';
import {
	Container,
	createStyles,
	Group,
	Pagination,
	ScrollArea,
	Text,
} from '@mantine/core';
import { IMeeting } from '../../interfaces/meeting';
import { logger } from '../../helpers/logger';
import { actionTableSelection, currentActions } from '../../Atoms/actions';
import ActionsTable from './components/ActionsTable/ActionsTable';
import { useNavigate } from 'react-router';
import { decode, encode } from 'js-base64';
import { useSearchParams } from 'react-router-dom';
import { NavigateParams } from '../../components/Meetings/Meetings';
import AssignActionModal from '../../components/Modals/AssignActionModal';
import {
	assignActionModalOpenState,
	assigningActionAtom,
} from '../../Atoms/meetingAtom';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { modals } from '@mantine/modals';
import { IAction } from '../../interfaces/action';
import { DateTime } from 'luxon';
import { isMeetingOwnerOrProjectManager } from '../../helpers/auth';

const useStyles = createStyles(() => ({
	outerContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		alignItems: 'center',
	},
}));

export interface Props {
	segmentValue: string;
}

const checkActionAuthorization = (
	actions: IAction[],
	userId: string,
	userRoles: string[]
): { authorized: boolean; unauthorizedActions: IAction[] } => {
	const unauthorizedActions = actions.filter(
		(action) =>
			!isMeetingOwnerOrProjectManager(
				action.meeting.organizer,
				action.meeting.owningUserID,
				userId,
				userRoles
			)
	);

	return {
		authorized: unauthorizedActions.length === 0,
		unauthorizedActions,
	};
};

const handleUnauthorizedActions = (
	unauthorizedActions: IAction[],
	actionType: string
): void => {
	const actionsList = unauthorizedActions
		.map((action) => `\n- ${action.content}`)
		.join('');

	showFailureNotification({
		message: `You are not authorized to ${actionType} ${
			unauthorizedActions.length
		} of the selected action${
			unauthorizedActions.length > 1 ? 's' : ''
		}. Unauthorized actions:${actionsList}`,
	});
};

export default function Actions({ segmentValue }: Props) {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const user = useRecoilValue(currentUser);
	const [searchParams] = useSearchParams();
	const skipParam = searchParams.get('skip');
	const skip = isNaN(skipParam as unknown as number) ? 0 : Number(skipParam);
	const order = searchParams.get('order') ?? '-assignedTo';
	const searchParam = searchParams.get('search');
	const search = decode(searchParam ? String(searchParam).trim() : '');
	const setCurrentActions = useSetRecoilState(currentActions);
	const [selection, setSelection] = useRecoilState(actionTableSelection);
	const [fetchingActions, setFetchingActions] = useState(true);
	const currentOrg = useRecoilValue(userOrganization);
	const [meetings, setMeetings] = useRecoilState(currentUserReelays);
	const setAssigningAction = useSetRecoilState(assigningActionAtom);
	const setAssignActionModalOpened = useSetRecoilState(
		assignActionModalOpenState
	);
	const [assigningActions, setAssigningActions] = useState<IAction[]>([]);
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		null,
		null,
	]);

	const isThereAtLeastOneAction = meetings.some(
		(meeting: IMeeting) => meeting?.actions?.length > 0
	);
	const { firstName, lastName, currentOrganizationID } = user;

	const [viewType, setViewType] = useState(segmentValue ?? 'todo');
	const [currentOrder, setCurrentOrder] = useState('-publishedAt');

	const changeSegmentValue = (value: string) => {
		setViewType(value);
		navigateTo({
			segment: value,
			search: encode(search),
		});
	};

	const changePage = (value: number) => {
		navigateTo({
			segment: currentSegmentValue,
			skip: (value > 0 ? value - 1 : 0) * limit,
			limit,
			search: encode(search),
			order,
		});
	};

	const changeSort = (value: string) => {
		navigateTo({
			segment: currentSegmentValue,
			skip,
			limit,
			order: value,
			search: encode(search),
		});
	};

	const navigateTo = (params: NavigateParams) => {
		const { segment, skip, limit, order, search } = params;
		const query = getQuery({ skip, limit, order, search });
		const uri = `/actions/${segment !== 'live' ? segment : ''}?${query}`;
		return navigate(uri);
	};

	const handleSearch = (value: string) => {
		return navigateTo({
			segment: currentSegmentValue,
			search: encode(value),
		});
	};

	const [isInitialized, setInitialized] = useState(false);

	useEffect(() => {
		const loadPage = async () => {
			await refresh();
			setInitialized(true);
		};
		loadPage();
	}, []);

	useEffect(() => {
		handleGetActions({
			skip,
			limit,
			order,
			search,
		});
	}, [fetchingActions]);

	const [currentViewType, setCurrentViewType] = useState('card');
	const [currentSearchValue, setCurrentSearchValue] = useState('');

	useEffect(() => {
		if (!isInitialized) return;
		if (currentViewType !== viewType) {
			setCurrentViewType(viewType);
		}
		refresh();
	}, [viewType, skip, search, order]);

	useEffect(() => {
		if (!isInitialized) return;

		if (dateRange[0] && dateRange[1]) {
			refresh();
		} else if (dateRange[0] === null && dateRange[1] === null) {
			refresh();
		}
	}, [dateRange]);

	useEffect(() => {
		setViewType(segmentValue);
	}, [segmentValue]);

	useEffect(() => {
		setSelection([]);
	}, [viewType, segmentValue]);

	const refresh = async () => {
		if (loading) {
			return;
		}

		try {
			const currentSkip = (page - 1) * limit;

			if (viewType !== currentSegmentValue) {
				setLoading(true);
				setSegmentValue(viewType);
				setPage(Math.floor(skip / limit) + 1);

				await handleGetActions({
					skip,
					limit,
					order,
					search,
				});
			} else if (skip !== currentSkip) {
				await handleGetActions({
					skip,
					limit,
					order,
					search,
				});
			} else if (currentSearchValue !== search) {
				setCurrentSearchValue(search);
				await handleGetActions({
					skip: 0,
					search,
				});
			} else if (currentOrder !== order) {
				setCurrentOrder(order);
				await handleGetActions({
					skip,
					limit,
					order,
					search,
				});
			} else {
				await handleGetActions({
					skip,
					limit,
					order,
					search,
				});
			}
		} catch (error) {
			console.error(`Meetings.refresh: error:`, error);
			logger('error', 'Meetings.refresh err', error);
		} finally {
			setLoading(false);
		}
	};

	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(20);
	const [currentSegmentValue, setSegmentValue] = useState('');

	const handleGetActions = async (params?: any) => {
		try {
			if (loading) return;
			setLoading(true);
			const { searchValue = '', skip = 0 } = params || {};

			setPage(Math.floor(skip / limit) + 1);

			const filter: {
				actionableStatus: 'todo' | 'archive';
				startAt?: number;
				endAt?: number;
			} = {
				actionableStatus: null,
			};

			const currentOrder = order;

			if (viewType === 'todo') {
				filter.actionableStatus = 'todo';
			} else {
				filter.actionableStatus = 'archive';
			}

			if (dateRange[0] && dateRange[1]) {
				filter.startAt = dateRange[0].getTime();
				filter.endAt = dateRange[1].getTime();
			}

			const query = {
				organizationID: user.currentOrganizationID,
				...filter,
				search,
				limit: params.limit || 20,
				offset: isNaN(params.skip) ? 0 : Number(params.skip),
				order: currentOrder,
			};
			const response = await getActions(query);

			const { count, data } = (response as any)?.data || {};

			if (count !== undefined) setTotal(count);
			if (data !== undefined) setData(data);
		} catch (error) {
			setTotal(0);
			setData([]);
			showFailureNotification({
				message:
					'Failed to fetch actions. Make sure you have actions created and assigned.',
			});
			console.error(`Meetings.handleGetActions: error:`, error);
			logger('error', 'Meetings.handleGetActions err', error);
		} finally {
			setLoading(false);
		}
	};

	const handleAssign = (action: IAction) => {
		const { authorized, unauthorizedActions } = checkActionAuthorization(
			[action],
			user.id,
			user.roles
		);

		if (!authorized) {
			handleUnauthorizedActions(unauthorizedActions, 'assign');
			return;
		}

		setAssigningAction(action);
		setAssignActionModalOpened(true);
	};

	const getActionsFromSelection = (selection) => {
		const actions = selection.reduce((arr, index) => {
			const action = data[index];
			if (action) {
				return [...arr, action];
			}
			return arr;
		}, []);
		return actions;
	};

	const convertToCSV = (arr) => {
		const csvRows = [];
		const headers = Object.keys(arr[0]);
		csvRows.push(headers.join(','));

		for (const row of arr) {
			const values = headers.map((header) => {
				const escaped = ('' + row[header]).replace(/"/g, '\\"');
				return `"${escaped}"`;
			});
			csvRows.push(values.join(','));
		}

		return csvRows.join('\n');
	};

	const handleBulkDownload = () => {
		try {
			if (!selection.length) {
				showFailureNotification({
					message: 'Select at least one action before using any bulk action.',
				});
				return;
			}
			const actions = getActionsFromSelection(selection);
			const actionsData = actions.map((action) => ({
				Action: action.content,
				Meeting: action?.meeting?.name,
				Date:
					action?.meeting?.startAt || action?.meeting?.createdAt
						? DateTime.fromMillis(
								+(action?.meeting?.startAt || action?.meeting?.createdAt)
						  ).toFormat('M/d/yy')
						: 'N/A',
			}));
			const csvData = convertToCSV(actionsData);
			const blob = new Blob([csvData], { type: 'text/csv' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`${firstName}_${lastName}_Action_Items_${DateTime.now().toFormat(
					'yyyy-LL-dd'
				)}`
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);
		} catch (error) {
			logger('error', 'Failed to download actions', error);
			showFailureNotification({
				message: 'Failed to download actions. Please try again.',
			});
		}
	};

	const handleBulkAssign = () => {
		try {
			if (!selection.length) {
				showFailureNotification({
					message: 'Select at least one action before using any bulk action.',
				});
				return;
			}
			const actions = getActionsFromSelection(selection);

			const { authorized, unauthorizedActions } = checkActionAuthorization(
				actions,
				user.id,
				user.roles
			);

			if (!authorized) {
				handleUnauthorizedActions(unauthorizedActions, 'assign');
				return;
			}

			setAssigningActions(actions);
			setAssignActionModalOpened(true);
		} catch (error) {
			logger('error', 'Failed to assign actions', error);
			showFailureNotification({
				message: 'Failed to assign actions. Please try again.',
			});
		}
	};

	const handleBulkRemind = async () => {
		try {
			if (!selection.length) {
				showFailureNotification({
					message: 'Select at least one action before using any bulk action.',
				});
				return;
			}
			const actions = getActionsFromSelection(selection);

			const { authorized, unauthorizedActions } = checkActionAuthorization(
				actions,
				user.id,
				user.roles
			);

			if (!authorized) {
				handleUnauthorizedActions(unauthorizedActions, 'remind');
				return;
			}

			const promises = actions.map((action) => {
				return sendActionReminderEmail(
					[action.id],
					action.meetingID,
					user.currentOrganizationID
				);
			});
			const res = await Promise.all(promises);
			await refresh();
			setSelection([]);
			showSuccessNotification({
				message: 'Actions successfully reminded!',
			});
		} catch (error) {
			logger('error', 'Failed to remind actions', error);
			showFailureNotification({
				message: 'Failed to remind actions. Please try again.',
			});
		}
	};

	const handleBulkDelete = async () => {
		if (!selection.length) {
			showFailureNotification({
				message: 'Select at least one action before using any bulk action.',
			});
			return;
		}
		const actions = getActionsFromSelection(selection);

		const { authorized, unauthorizedActions } = checkActionAuthorization(
			actions,
			user.id,
			user.roles
		);

		if (!authorized) {
			handleUnauthorizedActions(unauthorizedActions, 'delete');
			return;
		}

		openConfirmBulkDeleteModal(actions);
	};

	const confirmBulkDelete = async (actions: IAction[]) => {
		try {
			const promises = actions.map((action) => {
				const { id, meetingID } = action;
				const payload = [id];
				return destroyMeetingHighlights(
					meetingID,
					payload,
					user.currentOrganizationID
				);
			});
			const res = await Promise.all(promises);
			await refresh();
			setSelection([]);
			showSuccessNotification({
				message: 'Actions successfully deleted!',
			});
		} catch (error) {
			logger('error', 'Failed to delete actions', error);
			showFailureNotification({
				message: 'Failed to delete actions. Please try again.',
			});
		}
	};

	const openConfirmBulkDeleteModal = (actions: IAction[]) =>
		modals.openConfirmModal({
			title: `Delete ${actions.length} Actions`,
			children: (
				<Text size='sm'>
					Are you sure you want to delete these {actions.length} actions?
					Deleting them will also remove them from their associated meetings.
				</Text>
			),
			labels: { confirm: 'Delete', cancel: 'Cancel' },
			confirmProps: { color: 'red' },
			onCancel: () => console.log('Cancel'),
			onConfirm: () => confirmBulkDelete(actions),
		});

	const handleBulkActionableStatus = async (
		actionableStatus: 'todo' | 'archive'
	) => {
		try {
			if (!selection.length) {
				showFailureNotification({
					message: 'Select at least one action before using any bulk action.',
				});
				return;
			}

			const actions = getActionsFromSelection(selection);
			if (!actions.length) {
				showFailureNotification({
					message: 'No valid actions found to update.',
				});
				return;
			}

			// Group actions by meeting ID
			const actionsByMeetingID = actions.reduce((acc, action) => {
				const meetingID = action.meeting?.id;
				if (!meetingID) return acc; // Skip if no meeting ID

				if (!acc[meetingID]) {
					acc[meetingID] = [];
				}
				acc[meetingID].push(action.id);
				return acc;
			}, {});

			// Run all markActionItems calls in parallel
			await Promise.all(
				Object.entries(actionsByMeetingID).map(([meetingID, actionIDs]) => {
					return markActionItems(
						actionIDs as string[],
						actionableStatus,
						meetingID,
						user.currentOrganizationID
					);
				})
			);

			await refresh();
			setSelection([]);
			showSuccessNotification({
				message: `${actions.length} actions have been marked as ${actionableStatus}.`,
			});
		} catch (error) {
			logger('error', `Failed to mark actions as ${actionableStatus}`, error);
			showFailureNotification({
				message: `Failed to mark actions as ${actionableStatus}. Please try again.`,
			});
		}
	};

	return (
		<Container className={classes.outerContainer} size={'xl'} pb={'xl'}>
			<SearchActionsBar
				viewType={viewType}
				setViewType={setViewType}
				searchValue={search}
				handleSearch={handleSearch}
				changeSegmentValue={changeSegmentValue}
				handleBulkAssign={handleBulkAssign}
				handleBulkRemind={handleBulkRemind}
				handleBulkDownload={handleBulkDownload}
				handleBulkDelete={handleBulkDelete}
				handleBulkActionableStatus={handleBulkActionableStatus}
				dateRange={dateRange}
				setDateRange={setDateRange}
			/>
			<ScrollArea w={'100%'}>
				<ActionsTable
					actions={data}
					setActions={setData}
					viewType={viewType}
					searchValue={search}
					meetingCount={total}
					rowsPerPage={limit}
					fetchingMeetings={loading}
					changeSort={changeSort}
					refresh={refresh}
					handleAssign={handleAssign}
				/>
			</ScrollArea>
			<Group position='right' h={32} my={'md'} w={'100%'}>
				<Pagination
					withEdges
					value={page}
					onChange={changePage}
					total={Math.ceil(total / limit)}
				/>
				<Text mt={0} color='secondary-text' align={'center'} size={12}>
					{`${skip}-${skip + limit > total ? total : skip + limit} of ${total}`}
				</Text>
			</Group>

			<div id='sharing-modals'>
				<AssignActionModal
					assigningActions={assigningActions}
					setAssigningActions={setAssigningActions}
					refresh={refresh}
				/>
			</div>
		</Container>
	);
}
